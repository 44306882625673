import React from 'react';
import { Route } from 'react-router-dom';
import TenantPermissionProvider from '../services/TenantPermissionProvider';
import PropTypes from 'prop-types';
import TenantPermissions from '../enums/TenantPermissions';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';

function RouteWrapper({ children, permission = null, ...other }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Route {...other}>
        {permission !== null ? (
          <TenantPermissionProvider permission={permission}>{children}</TenantPermissionProvider>
        ) : (
          children
        )}
      </Route>
    </ErrorBoundary>
  );
}

RouteWrapper.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.oneOf(Object.values(TenantPermissions)),
};

export default RouteWrapper;
