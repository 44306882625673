import { CircularProgress, styled, makeStyles } from '@material-ui/core';
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  circularProgress: {
    color: 'black',
    outline: '1px  white',
  },
}));

function LoadingSuspense({ children }) {
  return <Suspense fallback={<BoxLoading />}>{children}</Suspense>;
}

const StyledContainer = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  textAlign: 'center',
});

export function BoxLoading({ children }) {
  const classes = useStyles();

  return (
    <StyledContainer aria-busy='true' aria-label='Loading...'>
      <CircularProgress className={classes.circularProgress} />
      {children}
    </StyledContainer>
  );
}

export function BoxScreenCenter({ children }) {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  );
}

BoxScreenCenter.propTypes = {
  children: PropTypes.node,
};

BoxLoading.propTypes = {
  children: PropTypes.node,
};

LoadingSuspense.propTypes = {
  children: PropTypes.node,
};

export default LoadingSuspense;
