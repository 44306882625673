import React, { useCallback, useMemo } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import PropTypes from 'prop-types';
import AutocompleteMaps from '../../suggested-actions/google-maps/AutocompleteMaps';
import { hooks } from 'botframework-webchat-component';
import { EventList } from '../../utils/EventList';
import LoadScriptWrapper from '../../../../components/LoadScriptWrapper';
import { makeStyles } from '@material-ui/core/styles';

const { useSendEvent } = hooks;

const useStyles = makeStyles(() => ({
  mapModalOverlay: {
    padding: '24px',
  },
}));

const EditModalAddressGoogleMaps = ({
  children,
  openModalOverlay,
  setOpenModalOverlay,
  editableKey,
  editType,
  arrayIndexKey,
  arrayKey,
  editLabels,
}) => {
  const classes = useStyles();
  const sendEvent = useSendEvent();

  const googleMapsUrl = useMemo(
    () => `${window.location.protocol}//${window.location.host}/api/app-config/maps-script`,
    [],
  );

  const onLocationSelectedHandler = useCallback(
    (address) => {
      const size = '400x400';
      const mapsStaticApiUrl = `${window.location.protocol}//${window.location.host}/api/app-config/static-map/`;
      const locationImage = `${mapsStaticApiUrl}?center=${address.address}&markers=color:red|${address.address}&zoom=13&size=${size}`;

      sendEvent(EventList.EditAnswerEvent, {
        editableKey: editableKey,
        editValue: address.address,
        editData: {
          shortAddress: address.shortAddress,
          address: address.address,
          latitude: address.pinLatAndLong.lat,
          longitude: address.pinLatAndLong.lng,
          postcode: address.postcode,
          country: address.country,
          administrativeArea: address.administrativeArea,
        },
        arrayKey: arrayKey,
        arrayIndexKey: arrayIndexKey,
        editType: editType,
        locationImage: locationImage,
      });
      setOpenModalOverlay(false);
    },
    [sendEvent, editType, editableKey, arrayIndexKey, arrayKey, setOpenModalOverlay],
  );

  return (
    <>
      <div style={{ display: !openModalOverlay ? 'none' : '' }}>
        <ModalOverlay
          className={classes.mapModalOverlay}
          open={openModalOverlay}
          onClose={() => setOpenModalOverlay(false)}
        >
          <ModalOverlayTitle style={{ marginBottom: '-28px' }}>{editLabels.header}</ModalOverlayTitle>
          <ModalOverlayBody>
            <LoadScriptWrapper url={googleMapsUrl}>
              <AutocompleteMaps
                onLocationSelected={onLocationSelectedHandler}
                mapLabels={editLabels}
                initialAddress={children !== 'At home' ? children : null}
              />
            </LoadScriptWrapper>
          </ModalOverlayBody>
        </ModalOverlay>
      </div>
    </>
  );
};

EditModalAddressGoogleMaps.propTypes = {
  children: PropTypes.node,
  editableKey: PropTypes.string,
  arrayKey: PropTypes.string,
  arrayIndexKey: PropTypes.number,
  editLabels: PropTypes.object,
  editType: PropTypes.string,
  setOpenModalOverlay: PropTypes.func,
  openModalOverlay: PropTypes.bool,
};

export default EditModalAddressGoogleMaps;
