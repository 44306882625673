import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ClaimButton from '../../../components/ClaimButton';
import { hooks } from 'botframework-webchat-component';
import { useClaimFile } from '../../../services/ClaimFileContextProvider';

const { useSendMessageBack } = hooks;

const CompleteFileUploadButton = ({ cardAction: { title, text, value }, buttonProperties }) => {
  const inputSettings = value ? JSON.parse(value) : null;
  const displayText =
    inputSettings && inputSettings.DisplayText !== undefined ? inputSettings.DisplayText : '';
  const stepKey = inputSettings && inputSettings.StepKey !== undefined ? inputSettings.StepKey : '';

  const sendMessageBack = useSendMessageBack();

  const sendMessageBackCallBack = useCallback(
    (valueParam, textParam, displayTextParam) => sendMessageBack(valueParam, textParam, displayTextParam),
    [sendMessageBack],
  );

  const claimStepFiles = useClaimFile();

  const handleCompleteUploadClick = useCallback(() => {
    const currentStepItem = claimStepFiles.current.find((claimStep) => claimStep.stepKey === stepKey);
    sendMessageBackCallBack(currentStepItem?.files, text, displayText);
  }, [claimStepFiles, displayText, sendMessageBackCallBack, stepKey, text]);

  return (
    <>
      {title && <ClaimButton handleClick={handleCompleteUploadClick} label={title} {...buttonProperties} />}
    </>
  );
};

export default CompleteFileUploadButton;

CompleteFileUploadButton.defaultProps = {
  cardAction: undefined,
  buttonProperties: undefined,
};

CompleteFileUploadButton.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};
