import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { hooks } from 'botframework-webchat-component';
import ChoiceSetAdaptiveCard from './adaptive-cards/ChoiceSetAdaptiveCard';
import HeroCard from './adaptive-cards/HeroCard';
import PdfChatDisplay from './attachments/PdfChatDisplay';
import MessageBubble from './message-bubble/MessageBubble';
import BotAvatar from './avatar/BotAvatar';
import VideoChatDisplay from './attachments/VideoChatDisplay';
import PhotoChatDisplay from './attachments/PhotoChatDisplay';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import { maskContent, maskWithTemplate, getMaskedActivityState } from './utils/activity-utils';
import DocxChatDisplay from './attachments/DocxChatDisplay';
import XlsxChatDisplay from './attachments/XlsxChatDisplay';
import HtmlChatDisplay from './attachments/HtmlChatDisplay';
import VideoPlayer from '../../components/VideoPlayer';

const { useRenderMarkdownAsHTML } = hooks;

const useStyles = makeStyles((theme) => ({
  avatar: {
    alignSelf: 'flex-end',
    marginRight: theme.spacing(0.5),
    height: 20,
    width: 20,
  },
  botWrapper: {
    display: 'flex',
  },
  qrWrapper: {
    display: 'flex',
    marginBottom: '4px',
  },
  adaptiveCard: {
    listStyleType: 'none',
    paddingInlineStart: '0px',
    width: '100%',
  },
  qrCodeImageWrapper: {
    maxWidth: '130px',
    padding: '4px',
  },
}));

const getProcessedContentType = (contentType) => {
  if (contentType.startsWith('image/')) {
    return 'image';
  } else if (contentType.startsWith('video/')) {
    return 'video';
  }
  return contentType;
};

const Attachment = ({
  from,
  fromId,
  content,
  contentUrl,
  contentType,
  name,
  id,
  fileName,
  activityId,
  modalProperties,
  hideDeleteButton,
  editableKey,
  arrayKey,
  arrayIndexKey,
  validationRegex,
  validationMessage,
  editType,
  timeKey,
  dateKey,
  editLabels,
  properties,
  isContinue,
  maskType,
  isUndoIconVisible,
  fileExtension,
  webApiToken,
  utcDateTimeProp,
  masktemplate,
  minLength,
  minLengthValidationMsg,
  maxLength,
  maxLengthValidationMsg,
  keyboardType,
  activityIndex,
  previousActivitiesCount,
  hiddenIconsStatuses
}) => {
  const classes = useStyles();
  const [openModalOverlay, setOpenModalOverlay] = useState(false);
  const renderMarkdown = useRenderMarkdownAsHTML();
  const processedContentType = getProcessedContentType(contentType);

  const isActivityMasked = getMaskedActivityState(isContinue, activityIndex, previousActivitiesCount);

  if (isContinue && maskType && isActivityMasked) {
    content = maskContent(maskType, content);
  }

  if (isContinue && masktemplate && isActivityMasked) {
    content = maskWithTemplate(masktemplate, content);
  }

  switch (processedContentType) {
    case 'qrCodeImage':
      return (
        <div className={classes.qrWrapper}>
          <BotAvatar />
          <MessageBubble>
            <img className={classes.qrCodeImageWrapper} src={content} />
          </MessageBubble>
        </div>
      );
    case 'image':
      return (
        <PhotoChatDisplay
          webApiToken={webApiToken}
          name={name}
          fileId={id}
          activityId={activityId}
          fileExtension={fileExtension}
          isActivityMasked={isActivityMasked}
        />
      );

    case 'text/markdown':
      if (fromId === 'user' || from === 'user') {
        return (
          <MessageBubble from={from} fromId={fromId} isContinue={isContinue} maskType={maskType}>
            {content}
          </MessageBubble>
        );
      }
      if (modalProperties) {
        const link = document.getElementById(modalProperties.linkId);
        if (link) {
          link.onclick = () => {
            setOpenModalOverlay(true);
          };
        }
      }

      return (
        <div className={classes.botWrapper}>
          <BotAvatar />
          <MessageBubble from={from} fromId={fromId} isText={false}>
            <div dangerouslySetInnerHTML={{ __html: renderMarkdown(content) }} data-testid='text-markdown' />
          </MessageBubble>
          {modalProperties && (
            <ModalOverlay open={openModalOverlay} onClose={() => setOpenModalOverlay(false)}>
              <ModalOverlayTitle>{modalProperties.title}</ModalOverlayTitle>
              <ModalOverlayBody>{modalProperties.message}</ModalOverlayBody>
            </ModalOverlay>
          )}
        </div>
      );

    case 'text/plain':
      if (fromId === 'user' || from === 'user') {
        return (
          <MessageBubble
            from={from}
            fromId={fromId}
            editableKey={editableKey}
            arrayKey={arrayKey}
            arrayIndexKey={arrayIndexKey}
            validationRegex={validationRegex}
            editLabels={editLabels}
            editType={editType}
            timeKey={timeKey}
            dateKey={dateKey}
            properties={properties}
            validationMessage={validationMessage}
            isContinue={isContinue}
            maskType={maskType}
            isUndoIconVisible={isUndoIconVisible}
            utcDateTimeProp={utcDateTimeProp}
            minLength={minLength}
            minLengthValidationMsg={minLengthValidationMsg}
            maxLength={maxLength}
            maxLengthValidationMsg={maxLengthValidationMsg}
            keyboardType={keyboardType}
            hiddenIconsStatuses={hiddenIconsStatuses}
            activityId={activityId}
          >
            {content}
          </MessageBubble>
        );
      }
      if (modalProperties) {
        const link = document.getElementById(modalProperties.linkId);
        if (link) {
          link.onclick = () => {
            setOpenModalOverlay(true);
          };
        }
      }

      return (
        <div className={classes.botWrapper}>
          <BotAvatar />
          <MessageBubble isContinue={isContinue} from={from} fromId={fromId} maskType={maskType} properties={properties}>
            {content}
          </MessageBubble>
          {modalProperties && (
            <ModalOverlay open={openModalOverlay} onClose={() => setOpenModalOverlay(false)}>
              <ModalOverlayTitle>{modalProperties.title}</ModalOverlayTitle>
              <ModalOverlayBody>{modalProperties.message}</ModalOverlayBody>
            </ModalOverlay>
          )}
        </div>
      );

    case 'application/pdf':
      return (
        <PdfChatDisplay
          name={name}
          fileId={id}
          displayName={fileName}
          activityId={activityId}
          isActivityMasked={isActivityMasked}
        />
      );

    case 'application/doc':
    case 'application/docx':
      return (
        <DocxChatDisplay
          name={name}
          fileId={id}
          displayName={fileName}
          activityId={activityId}
          isActivityMasked={isActivityMasked}
        />
      );

    case 'application/xls':
    case 'application/xlsx':
      return (
        <XlsxChatDisplay
          name={name}
          fileId={id}
          displayName={fileName}
          activityId={activityId}
          isActivityMasked={isActivityMasked}
        />
      );

    case 'application/html':
      return (
        <HtmlChatDisplay
          name={name}
          fileId={id}
          displayName={fileName}
          activityId={activityId}
          isActivityMasked={isActivityMasked}
        />
      );

    case 'video':
      return (
        <VideoChatDisplay
          fileId={id}
          webApiToken={webApiToken}
          name={name}
          activityId={activityId}
          fileExtension={fileExtension}
          hideDeleteButton={hideDeleteButton ?? false}
          isActivityMasked={isActivityMasked}
        />
      );

    case 'application/vnd.microsoft.card.adaptive': {
      const textBlocks = content.body.filter(({ type }) => type === 'TextBlock');

      return (
        <React.Fragment>
          <ul className={classes.adaptiveCard} data-testid='adaptive-card-attachment'>
            {textBlocks &&
              textBlocks.map(({ text }, index) => (
                <React.Fragment key={index}>
                  {from === 'bot' && <BotAvatar />}
                  <MessageBubble from={from} fromId={fromId}>
                    <li>{text}</li>
                  </MessageBubble>
                </React.Fragment>
              ))}
            <li>
              <ChoiceSetAdaptiveCard adaptiveCard={content} />
            </li>
          </ul>
        </React.Fragment>
      );
    }

    case 'application/vnd.microsoft.card.hero':
      return <HeroCard content={content} />;


    case 'guideVideo':
      return ( <VideoPlayer {...properties} />);

    default:
      return (
        <React.Fragment>
          {from === 'bot' && <BotAvatar />}
          <MessageBubble from={from} fromId={fromId}>
            {contentUrl && (
              <a href={contentUrl} rel='noopener noreferrer' target='_blank'>
                {name}
              </a>
            )}
            {!contentUrl && <pre>Not implemented</pre>}
          </MessageBubble>
        </React.Fragment>
      );
  }
};

export default Attachment;

Attachment.defaultProps = {
  from: 'bot',
  contentType: '',
  name: '',
};

Attachment.propTypes = {
  from: PropTypes.string,
  fromId: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fileName: PropTypes.string,
  activityId: PropTypes.string,
  modalProperties: PropTypes.shape({
    linkId: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
  }),
  contentUrl: PropTypes.string,
  hideDeleteButton: PropTypes.bool,
  editableKey: PropTypes.string,
  arrayKey: PropTypes.string,
  arrayIndexKey: PropTypes.number,
  validationRegex: PropTypes.string,
  validationMessage: PropTypes.string,
  editLabels: PropTypes.object,
  editType: PropTypes.string,
  timeKey: PropTypes.string,
  dateKey: PropTypes.string,
  properties: PropTypes.any,
  isContinue: PropTypes.bool,
  maskType: PropTypes.string,
  isUndoIconVisible: PropTypes.bool,
  fileExtension: PropTypes.string,
  webApiToken: PropTypes.string,
  utcDateTimeProp: PropTypes.string,
  masktemplate: PropTypes.string,
  minLength: PropTypes.string,
  minLengthValidationMsg: PropTypes.string,
  maxLength: PropTypes.string,
  maxLengthValidationMsg: PropTypes.string,
  keyboardType: PropTypes.string,
  allActivitiesCount: PropTypes.number,
  activityIndex: PropTypes.number,
  previousActivitiesCount: PropTypes.number,
  hiddenIconsStatuses: PropTypes.any
};
