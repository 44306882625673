import React from 'react';
import { createContext, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { isJsonResponse } from '../utils/isJsonResponse';
import { isPdfResponse } from '../utils/isPdfResponse';

export function useFetcher() {

  return useCallback(
    /**
     *
     * @param { string } url
     * @param { RequestInit } options
     * @returns
     */
    async (url, options = {}) => {
      const { headers = {}, body, ...other } = options;
      const bodyStringify = body ? JSON.stringify(body) : null;
      const result = await fetch(url, {
        method: options.method || 'GET',
        headers: new Headers({
          'Content-Type': 'application/json',
          ...headers,
        }),
        body: bodyStringify,
        ...other,
      });
      let response;
      if (isJsonResponse(result)) {
        response = await result.json();
      } else if (isPdfResponse(result)) {
        response = await result.blob();
      } else {
        response = await result.text();
      }
      if (result.status >= 400) {
        throw { status: result.status, message: response };
      }
      return response;
    },
    [],
  );
}

const FetcherContext = createContext(null);
export const useQueryFetcher = () => useContext(FetcherContext);
export function FetcherProvider({ children }) {
  const fetcher = useFetcher();
  return <FetcherContext.Provider value={fetcher}>{children}</FetcherContext.Provider>;
}

FetcherProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
