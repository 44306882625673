import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const LanguageDataContext = createContext(null);
export const useLanguageData = () => useContext(LanguageDataContext);

export const withLanguageData = (Component) => {
  return (props) => {
    const { languageData, setLanguageData } = useLanguageData();
    return <Component languageData={languageData} setLanguageData={setLanguageData} {...props} />;
  };
};

function LanguageDataProvider({ children }) {
  const [languageData, setLanguageData] = useState(null);
  return (
    <LanguageDataContext.Provider value={useMemo(() => ({ languageData, setLanguageData }), [languageData])}>
      {children}
    </LanguageDataContext.Provider>
  );
}

LanguageDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LanguageDataProvider;
