import { makeStyles } from '@material-ui/core/styles';
import { hooks } from 'botframework-webchat-component';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import ClaimButton from '../../../components/ClaimButton';

const { useSendMessageBack } = hooks;

const useStyles = makeStyles((theme) => ({
  cardActionList: {
    textAlign: 'center',
    listStyleType: 'none',
    paddingInlineStart: 0,
    margin: theme.spacing(1, 3, 0),
  },
  shoMoreLessListItem: {
    padding: theme.spacing(1.25),
  },
  showMoreLessBtn: {
    color: '#008dff',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  buttonStyle: {
    marginBottom: theme.spacing(1),
    '&:focus': {
      outline: 'none',
    },
  },
  mapModalOverlay: {
    padding: '24px',
  },
}));

const HelpModal = ({ cardAction: { displayText, value }, ...buttonProperties }) => {
  const [isOpen, setIsOpen] = useState(false);
  const mapLabels = JSON.parse(value);
  const classes = useStyles();
  const sendMessageBack = useSendMessageBack();

  const handleClick = useCallback(
    (value, displaytext) => {
      sendMessageBack(displaytext, value, displaytext);
    },
    [sendMessageBack],
  );

  return (
    <>
      <ClaimButton handleClick={() => setIsOpen(true)} label={displayText} {...buttonProperties} />
      <div style={{ display: !isOpen ? 'none' : '' }}>
        <ModalOverlay className={classes.mapModalOverlay} open={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlayTitle style={{ marginBottom: '-28px' }}>{mapLabels.header}</ModalOverlayTitle>
          <ModalOverlayBody>
            <ul className={classes.cardActionList}>
              {mapLabels &&
                mapLabels.suggestedactions.map((item, index) => {
                  return (
                    <li
                      key={index}
                      className={classes.buttonStyle}
                      data-testid='suggested-action'
                      id={index === 0 ? 'first-action-item' : null}
                    >
                      <ClaimButton
                        fullWidth={true}
                        key={item.value}
                        handleClick={() => handleClick(item.value, item.displaytext)}
                        label={item.title}
                        {...buttonProperties}
                      />
                    </li>
                  );
                })}
            </ul>
          </ModalOverlayBody>
        </ModalOverlay>
      </div>
    </>
  );
};

export default HelpModal;

HelpModal.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};
