import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { hooks } from 'botframework-webchat-component';
import ClaimButton from '../../../components/ClaimButton';

const { useSendMessage } = hooks;

const ImBackButton = ({ cardAction: { title, value }, buttonProperties }) => {
  const sendMessage = useSendMessage();

  const handleClick = useCallback(() => sendMessage(value), [value, sendMessage]);

  // ImBack is essentially sending a message
  return <ClaimButton handleClick={handleClick} label={title} {...buttonProperties} />;
};

export default ImBackButton;

ImBackButton.defaultProps = {
  cardAction: undefined,
  buttonProperties: undefined,
};

ImBackButton.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};
