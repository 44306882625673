import 'moment/locale/en-gb';
import 'moment/locale/nl';
import 'moment/locale/fr';
import 'moment/locale/ja';

export const localeMap = Object.freeze({
  en: 'en-gb',
  nl: 'nl',
  fr: 'fr',
  ja: 'ja-JP'
});
