import React, { createContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from '../pages/web-chat/LoadingIndicator';

export const LoadingIndicatorContext = createContext(null);

export default function LoadingIndicatorProvider({ children }) {
  const [loadingIndicator, setLoadingIndicator] = useState({ display: false, progress: 0 });

  const loadingIndicatorProviderValue = useMemo(
    () => ({ loadingIndicator, setLoadingIndicator }),
    [loadingIndicator],
  );

  return (
    <LoadingIndicatorContext.Provider value={loadingIndicatorProviderValue}>
      {loadingIndicator.display && <LoadingIndicator />}
      {children}
    </LoadingIndicatorContext.Provider>
  );
}

LoadingIndicatorProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
