export const isTextualKeyboard = (kbType) => kbType == 'text' || kbType == 'email' || kbType == 'tel';
export const isNumericKeyboard = (kbType) => kbType == 'numeric';

export const minMaxValidation = (
  value,
  minLength,
  maxLength,
  minLengthValidationMsg,
  maxLengthValidationMsg,
  keyboardType,
  setCharsLeft,
) => {
  let errMsg = '';

  if (!maxLength && !minLength) return errMsg;

  let intMaxLength = parseInt(maxLength);
  let intMinLength = parseInt(minLength);

  if (isTextualKeyboard(keyboardType)) {
    if (!isNaN(intMaxLength)) {
      setCharsLeft(intMaxLength - value.length);
      errMsg = value.length >= intMaxLength && maxLengthValidationMsg;
    }
    if (!isNaN(intMinLength) && !errMsg) {
      errMsg = value.length < intMinLength && minLengthValidationMsg;
    }
    return errMsg;
  } else if (isNumericKeyboard(keyboardType)) {
    let intValue = parseInt(value);
    if (isNaN(intValue)) return errMsg;

    if (!isNaN(intMaxLength)) {
      errMsg = intValue > intMaxLength && maxLengthValidationMsg;
    }
    if (!isNaN(intMinLength) && !errMsg) {
      errMsg = intValue < intMinLength && minLengthValidationMsg;
    }
    return errMsg;
  }

  throw new Error('Invalid keyboard type for min max validation');
};
