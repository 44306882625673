import React, { createContext, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

export const ClientConfigsContext = createContext(null);
export const useClientConfigs = () => useContext(ClientConfigsContext);

export const withClientConfigs = (Component) => {
  return (props) => {
    const configs = useClientConfigs();
    return (
      <Component
        clientConfigs={configs?.clientConfigs}
        setClientConfigs={configs?.setClientConfigs}
        {...props}
      />
    );
  };
};

function ClientConfigsContextProvider({ children }) {
  const [clientConfigs, setClientConfigs] = useState(null);
  return (
    <ClientConfigsContext.Provider
      value={useMemo(() => ({ clientConfigs, setClientConfigs }), [clientConfigs])}
    >
      {children}
    </ClientConfigsContext.Provider>
  );
}

ClientConfigsContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ClientConfigsContextProvider;
