import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { colorPalette, Typo } from 'ri-components';

const { grey, black } = colorPalette;
const useStyles = makeStyles((theme) => ({
  infoBox: {
    maxWidth: '300px',
    width: '100%',
    backgroundColor: '#F6F6F6',
    padding: '20px 20px 20px 20px;',
    border: `1px solid ${grey[4]}`,
    borderRadius: '16px',
    wordBreak: 'break-word',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.between('xs', 350)]: {
      maxWidth: '240px',
    },
    [theme.breakpoints.between(350, 420)]: {
      maxWidth: '290px',
    },
  },
  regNumber: {
    padding: '6px 10px',
    borderRadius: '9px',
    marginTop: '6px',
    backgroundColor: '#FFD307',
    maxWidth: '100px',
    border: `1px solid ${black}`,
    fontWeight: 'bolder',
    textAlign: 'center',
  },
}));

const toTitleCase = (text) => {
  const titleCaseText = text[0].toUpperCase() + text.substr(1).toLowerCase();
  return titleCaseText;
};

const VehicleDataCard = ({ content }) => {
  const classes = useStyles();

  const vehicleRegistration = content.Response.DataItems.VehicleRegistration;

  return (
    <div className={classes.infoBox}>
      <Typo variant='bodyMd' fontWeight='medium'>
        {vehicleRegistration.Make}, {vehicleRegistration.Model}
      </Typo>
      <Typo variant='bodyMd'>
        {vehicleRegistration.Colour &&
          vehicleRegistration.Colour.toLowerCase() !== 'unknown' &&
          `${toTitleCase(vehicleRegistration.Colour)}, `}
        {vehicleRegistration.YearOfManufacture &&
          vehicleRegistration.YearOfManufacture !== '0' &&
          `${vehicleRegistration.YearOfManufacture}, `}
        {vehicleRegistration.FuelType &&
          vehicleRegistration.FuelType.toLowerCase() !== 'unknown' &&
          toTitleCase(vehicleRegistration.FuelType)}
      </Typo>
      <Typo variant='bodySm' fontWeight='bold' className={classes.regNumber}>
        {vehicleRegistration.Vrm}
      </Typo>
    </div>
  );
};

export default VehicleDataCard;

VehicleDataCard.defaultProps = {
  content: undefined,
};
VehicleDataCard.propTypes = {
  content: PropTypes.shape({
    Response: PropTypes.shape({
      DataItems: PropTypes.shape({
        VehicleRegistration: PropTypes.shape({
          Make: PropTypes.string,
          Model: PropTypes.string,
          Colour: PropTypes.string,
          YearOfManufacture: PropTypes.string,
          FuelType: PropTypes.string,
          Vrm: PropTypes.string,
        }),
      }),
    }),
  }),
};
