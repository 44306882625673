import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, LinearProgress, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colorPalette, Typo } from 'ri-components';
import { LoadingIndicatorContext } from '../../services/LoadingIndicatorContext';
import useIsMonted from '../../hooks/useIsMounted';
import { useLanguageData } from '../../services/LanguageDataProvider';

const { white } = colorPalette;

const StyledLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  barColorPrimary: {
    backgroundColor: white,
  },
  root: {
    margin: '8px 16px 0',
  },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    color: white,
    backgroundColor: theme.palette.primary.main,
    padding: '8px 0',
    fontSize: '20px',
    maxWidth: '400px',
    lineHeight: '1.2',
    borderRadius: '22px',
    overflowWrap: 'break-word',
    letterSpacing: '-0.2px',
    width: '40%',
    margin: '10px 24px',
    marginBottom: '12px',
    marginLeft: 'auto',
  },
  loadingText: {
    margin: '8px 12px 4px',
    textAlign: 'center',
    color: white,
  },
  circularProgress: {
    display: 'block',
    margin: '0 auto -8px',
    color: white,
  },
}));

const LoadingIndicator = () => {
  const classes = useStyles();
  const [displayingProcessing, setDisplayingProcessing] = useState(false);
  const { loadingIndicator } = useContext(LoadingIndicatorContext);
  const isMounted = useIsMonted();
  const { languageData } = useLanguageData();
  useEffect(() => {
    if (loadingIndicator.percent === 100) {
      setTimeout(() => {
        if (isMounted) setDisplayingProcessing(true);
      }, 25);
    } else {
      setDisplayingProcessing(false);
    }
  }, [isMounted, loadingIndicator.percent]);

  return (
    <div className={classes.loadingIndicator}>
      {displayingProcessing ? (
        <>
          <CircularProgress className={classes.circularProgress} size={20} />
          <Typo variant='bodyMd' className={classes.loadingText} data-testid='processing-uploading-info'>
            {languageData?.fileprocessing ?? 'processing'}
          </Typo>
        </>
      ) : (
        <>
          <StyledLinearProgress variant='determinate' value={loadingIndicator.percent} />
          <Typo variant='bodyMd' className={classes.loadingText} data-testid='uploading-info'>
            {languageData?.fileuploading ?? 'uploading'} {Math.round(loadingIndicator.percent)}%
          </Typo>
        </>
      )}
    </div>
  );
};

LoadingIndicator.propTypes = {};

export default LoadingIndicator;
