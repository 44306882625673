import { useMemo, useState, useEffect } from 'react';
import { hooks } from 'botframework-webchat-component';
import { getLastActivityByType, getLastActivityByName } from '../utils/activity-utils';

const { useActivities } = hooks;

function useSendBox() {
  const [activities] = useActivities();
  const [defaultPlaceholder, setDefaultPlaceholder] = useState('');
  const [defaultKeyboardType, setDefaultKeyboardType] = useState('text');
  const [isSendboxActive, setSendboxActive] = useState(false);
  const [removeSpaces, setRemoveSpaces] = useState(false);

  const initActivity = useMemo(() => getLastActivityByName(activities, 'SetWebchatSendboxPropertiesDialog'), [
    activities,
  ]);

  useEffect(() => {
    if (initActivity === null) {
      return;
    }

    if (initActivity.placeholder) {
      setDefaultPlaceholder(initActivity.placeholder);
    }

    if (initActivity.keyboardtype) {
      setDefaultKeyboardType(initActivity.keyboardtype);
    }

    if (initActivity.issendboxactive) {
      setSendboxActive(initActivity.issendboxactive);
    }

    if (initActivity.removespaces) {
      setRemoveSpaces(initActivity.removespaces);
    }
  }, [initActivity]);

  const memoizedValue = useMemo(() => getLastActivityByType(activities, 'message'), [activities]);

  if (memoizedValue) {
    return {
      isSendboxActive: memoizedValue.issendboxactive ? memoizedValue.issendboxactive : isSendboxActive,
      placeholderText: memoizedValue.placeholder ? memoizedValue.placeholder : defaultPlaceholder,
      keyboardType: memoizedValue.keyboardtype ? memoizedValue.keyboardtype : defaultKeyboardType,
      removeSpaces: memoizedValue.removespaces ? memoizedValue.removespaces : removeSpaces,
    };
  }

  return {
    isSendboxActive: isSendboxActive,
    placeholderText: defaultPlaceholder,
    keyboardType: defaultKeyboardType,
    removeSpaces: removeSpaces,
  };
}

export default useSendBox;
