import React from 'react';
import PropTypes from 'prop-types';

import ImBackButton from './ImBackButton';
import MessageBackButton from './MessageBackButton';
import PostBackButton from './PostBackButton';
import UploadFileButton from './UploadFileButton';
import DatePickerButton from './DatePickerButton';
import RIDatePickerButton from './ri-date-picker/RIDatePickerButton';
import GoogleMapsButton from './GoogleMapsButton';
import UkVehicleAddressSearch from './address-search/UKVehicleAddessSearch';
import TimePickerButton from './TimePickerButton';
import CompleteFileUploadButton from './CompleteFileUploadButton';
import SubmitClaimMessageBack from './SubmitClaimMessageBack';
import ReloadConversationButton from './ReloadConversationButton';
import { detect } from 'detect-browser';
import MultiSelectButtons from './MultiSelectButtons';
import BeValuedItemLookup from './be-valued/BeValuedItemLookup';
import ImageSelectButtons from './ImageSelectButtons';
import HelpModal from './help-modal';
import RedirectToBooking from './BookingRedirect';
import ContactNumberProvider from './contact-number-provider/ContactNumberProvider';
import GoogleMapsWithMarkersButton from './GoogleMapsWithMarkersButton';
// "cardAction" could be either, "imBack", "messageBack", "postBack", or "uploadFile".
const CardActionButton = ({
  cardAction,
  buttonProperties,
  disableAllActionsCallBack,
  inputValue,
  sendMessageBackWithPdfCheck,
}) => {
  switch (cardAction.type) {
    case 'messageBack':
      return (
        <MessageBackButton
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );

    case 'submitClaimMessageBack':
      return (
        <SubmitClaimMessageBack
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          inputValue={inputValue}
        />
      );

    case 'postBack':
      return <PostBackButton cardAction={cardAction} buttonProperties={buttonProperties} />;

    case 'uploadFile':
      return (
        <UploadFileButton
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          disableAllActionsCallBack={disableAllActionsCallBack}
        />
      );

    case 'uploadFileComplete':
      return <CompleteFileUploadButton cardAction={cardAction} buttonProperties={buttonProperties} />;

    case 'dateInput':
      return (
        <DatePickerButton
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );

    case 'RIDateInput':
      return (
        <RIDatePickerButton
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );

    case 'googleMaps':
      return (
        <GoogleMapsButton
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );

    case 'UkVehicleAddressSearch':
      return (
        <UkVehicleAddressSearch
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );

    case 'reloadPage':
      return <ReloadConversationButton cardAction={cardAction} buttonProperties={buttonProperties} />;

    case 'timeInput':
      return (
        <TimePickerButton
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );

    case 'provideToUserPossibilityToContinueOnMobileDevice': {
      const browserData = detect();
      if (browserData.os !== 'Android OS' && browserData.os !== 'iOS')
        return (
          <MessageBackButton
            cardAction={cardAction}
            buttonProperties={buttonProperties}
            sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
          />
        );
      else return null;
    }
    case 'multiSelect': {
      return (
        <MultiSelectButtons
          cardAction={cardAction}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );
    }
    case 'BeValuedItemLookup': {
      return (
        <BeValuedItemLookup
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );
    }
    case 'googleMapWithMarkers': {
      return (
        <GoogleMapsWithMarkersButton
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );
    }
    case 'helpModal': {
      return <HelpModal cardAction={cardAction} buttonProperties={buttonProperties} />;
    }
    case 'imageBack': {
      return <ImageSelectButtons cardAction={cardAction} />;
    }
    case 'RedirectToBooking': {
      return <RedirectToBooking cardAction={cardAction} buttonProperties={buttonProperties} />;
    }
    case 'phoneNumberProvider': {
      return (
        <ContactNumberProvider
          cardAction={cardAction}
          buttonProperties={buttonProperties}
          sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
        />
      );
    }
    default:
      return <ImBackButton cardAction={cardAction} buttonProperties={buttonProperties} />;
  }
};

export default CardActionButton;

CardActionButton.propTypes = {
  cardAction: PropTypes.object,
  inputValue: PropTypes.string,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  disableAllActionsCallBack: PropTypes.func,
  sendMessageBackWithPdfCheck: PropTypes.func,
};
