import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ClaimButton from '../../../components/ClaimButton';
import { useCallback } from 'react';
import { makeStyles, Icon } from '@material-ui/core';
import { colorPalette } from 'ri-components';
import { CheckCircle } from '@material-ui/icons';
import useLastActivity from '../hooks/useLastActivity';
import clsx from 'clsx';

const { grey, green } = colorPalette;

const useStyles = makeStyles((theme) => ({
  continueButton: {
    color: '#008dff',
    fontSize: '16px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    alignSelf: 'center',
    '&:disabled': {
      color: grey[5],
      cursor: 'auto',
    },
  },
  buttonStyle: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkMarkContainer: {
    position: 'absolute',
    right: '10px',
  },
}));

const arrayToString = (array) => {
  let arrayAsString = '';
  array.map((x) => {
    arrayAsString = arrayAsString + x + ', ';
  });
  return arrayAsString.slice(0, -2);
};

const MultiSelectButtons = ({ cardAction, sendMessageBackWithPdfCheck }) => {
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [selectedButtonsText, setSelectedButtonsText] = useState([]);
  const classes = useStyles();
  const lastActivity = useLastActivity();

  const handleClick = useCallback(
    (cardAction) => {
      if (selectedButtons.includes(cardAction.value)) {
        setSelectedButtons((current) =>
          current.filter((x) => {
            return x !== cardAction.value;
          }),
        );
        setSelectedButtonsText((current) =>
          current.filter((x) => {
            return x !== cardAction.displayText;
          }),
        );
      } else {
        setSelectedButtons((current) => [...current, cardAction.value]);
        setSelectedButtonsText((current) => [...current, cardAction.displayText]);
      }
    },
    [selectedButtons],
  );

  const onContinue = useCallback(() => {
    sendMessageBackWithPdfCheck(
      selectedButtons,
      selectedButtons.toString(),
      arrayToString(selectedButtonsText),
    );
  }, [selectedButtons, selectedButtonsText, sendMessageBackWithPdfCheck]);

  return (
    <div>
      {cardAction.value.map((x) => (
        <div className={classes.buttonStyle} key={x.value} id={x.value}>
          <ClaimButton
            key={x.value}
            label={x.text}
            enableToggle={true}
            handleClick={() => {
              handleClick(x);
            }}
            hasIcon={true}
          >
            <Icon className={classes.checkMarkContainer}>
              <CheckCircle className={clsx('check-circle')} htmlColor={green[6]} />
            </Icon>
          </ClaimButton>
        </div>
      ))}
      <button
        className={clsx(classes.continueButton, 'continue-option')}
        onClick={() => onContinue()}
        tabIndex={0}
        disabled={!(selectedButtons.length > 0)}
      >
        {lastActivity?.continuebuttonlabel}
      </button>
    </div>
  );
};

export default MultiSelectButtons;

MultiSelectButtons.defaultProps = {
  cardAction: undefined,
};

MultiSelectButtons.propTypes = {
  cardAction: PropTypes.object,
  sendMessageBackWithPdfCheck: PropTypes.func,
};
