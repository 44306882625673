import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ClaimButton from '../../../components/ClaimButton';
import { DirectLineContext } from '../../../services/DirectLineContext';

const ReloadConversationButton = ({ cardAction: { title }, buttonProperties }) => {
  const { setDirectLine } = useContext(DirectLineContext);

  const reloadConversation = () => {
    setDirectLine(null);
  };

  return <ClaimButton handleClick={reloadConversation} label={title} {...buttonProperties} />;
};

ReloadConversationButton.propTypes = {
  cardAction: PropTypes.shape({
    title: PropTypes.string,
  }),
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};

export default ReloadConversationButton;
