export function getPostcode(place) {
  let postcode = '';
  if (place.address_components) {
    const pcs = place.address_components.filter((x) => x.types.filter((y) => y === 'postal_code').length > 0);
    if (pcs.length > 0) {
      postcode = pcs[0].long_name;
    }
  }
  return postcode;
}

export function getCountry(place) {
  let country_data = place.address_components?.find((item) => item.types.includes('country'));

  return country_data?.long_name;
}

export function getAdministrativeArea(place) {
  let administrativeArea = place.address_components?.find((item) =>
    item.types.includes('administrative_area_level_1'),
  );

  return administrativeArea?.long_name;
}

export function getShortAddress(place) {
    let shortAdress_data = place.formatted_address;
    if (
        place.address_components?.some((item) => item.types.includes('route')) &&
        place.address_components?.some((item) => item.types.includes('postal_town'))
    ) {
        shortAdress_data =
            place.address_components.find((item) => item.types.includes('route'))?.short_name + ', ' +
            place.address_components.find((item) => item.types.includes('postal_town'))?.short_name;
    }

    if (
        place.address_components?.some((item) => item.types.includes('route')) &&
        place.address_components?.some((item) => item.types.includes('postal_town')) &&
        place.address_components?.some((item) => item.types.includes('premise'))
    ) {
        shortAdress_data =
            place.address_components.find((item) => item.types.includes('premise'))?.short_name + ', ' +
            place.address_components.find((item) => item.types.includes('route'))?.short_name + ', ' +
            place.address_components.find((item) => item.types.includes('postal_town'))?.short_name;
    }

    return shortAdress_data;
}

export default { getPostcode, getCountry, getAdministrativeArea, getShortAddress };
