/* eslint-disable no-undef */

import React, { useRef, useEffect, useState } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { makeStyles } from '@material-ui/core/styles';
import './googleStyles.css';
import { getCountry, getPostcode, getAdministrativeArea, getShortAddress } from './GoogleMapsHelper';

const useStyles = makeStyles(() => ({
  marker: {
    position: 'absolute',
    left: '48%',
    top: '38%',
    zIndex: '100',
    pointerEvents: 'none',
  },
}));

const mapDefaultValues = {
  zoom: 6,
  coordinates: {
    lat: 54.24,
    lng: -2,
  },
};

const RiGoogleMaps = withGoogleMap((props) => {
  let mapValues;
  if (props.mapDefaultValues) {
    mapValues = props.mapDefaultValues;
  } else {
    mapValues = mapDefaultValues;
  }

  const mapRef = useRef(null);
  const [latLng, setLatLng] = useState({ lat: mapValues.coordinates.lat, lng: mapValues.coordinates.lng });
  const { onLatAndLogChanged } = props;
  const { fromHandler } = props;
  const geocoder = useRef();

  const classes = useStyles();

  useEffect(() => {
    geocoder.current = new google.maps.Geocoder();
  }, []);

  useEffect(() => {
    geocoder.current.geocode({ address: props.address }, (results, status) => {
      if (status === 'OK' && (props.isFromInput === true || fromHandler)) {
        setLatLng({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
        mapValues.zoom = 16;
      }
    });
  }, [props.address, props.isFromInput, fromHandler, mapValues]);

  useEffect(() => {
    mapRef.current.panTo(latLng);
    if (!fromHandler) {
      onLatAndLogChanged(latLng);
    }
  }, [latLng, onLatAndLogChanged, fromHandler]);

  const onBoundsChangedHandler = () => {
    const mapCenter = mapRef.current.getCenter();

    mapRef.current.panTo({ lat: mapCenter.lat(), lng: mapCenter.lng() });
    setLatLng({ lat: mapCenter.lat(), lng: mapCenter.lng() });
    geocoder.current.geocode({ location: { lat: mapCenter.lat(), lng: mapCenter.lng() } }, (results) => {
      if (results !== null && results !== undefined) {
        const shortAddress = getShortAddress(results[0]);
        const address = results[0].formatted_address;
        const postcode = getPostcode(results[0]);
        const country = getCountry(results[0]);
        const administrativeArea = getAdministrativeArea(results[0]);
        props.onMapChanged(shortAddress, address, postcode, country, administrativeArea);
        props.onMapAdjusted();
      }
    });
  };

  const onZoomChangedHandler = () => {
    mapValues.zoom = mapRef.current.getZoom();
  };

  return (
    <>
      {props.showCenterMarker && (
        <div className={classes.marker}>
          <section>
            <img src='https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png' />
          </section>
        </div>
      )}
      <GoogleMap
        onDragEnd={props.onMapChanged ? onBoundsChangedHandler : null}
        onZoomChanged={onZoomChangedHandler}
        ref={mapRef}
        zoom={mapValues.zoom}
        defaultCenter={mapValues.coordinates}
        options={{
          streetViewControl: props.streetViewControl,
          mapTypeControl: props.mapTypeControl,
          mapTypeControlOptions: {
            mapTypeIds: [google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.ROADMAP],
          },
          rotateControl: false,
          fullscreenControl: false,
          zoomControl: true,
          scrollwheel: true,
          gestureHandling: 'auto',
        }}
      >
        {props.markIncidentPlace && <Marker position={latLng} />}
      </GoogleMap>
    </>
  );
});

export default RiGoogleMaps;
