import getValueOrUndefined from './getValueOrUndefined';
import { EventList } from './EventList';

export function getLastActivityByType(activities, activityType) {
  if (activities && activities.length === 0) {
    return null;
  }

  let filteredActivities = [];
  if (activityType && activityType !== '') {
    filteredActivities = activities.filter((activity) => activity.type === activityType);
  }

  if (filteredActivities && filteredActivities.length === 0) {
    return null;
  }

  return filteredActivities[filteredActivities.length - 1];
}

export function getLastActivityByName(activities, activityName) {
  if (activities && activities.length === 0) {
    return null;
  }

  let filteredActivities = [];

  if (activityName && activityName !== '') {
    filteredActivities = activities.filter((activity) => activity.name === activityName);
  }

  if (filteredActivities && filteredActivities.length === 0) {
    return null;
  }

  return filteredActivities[filteredActivities.length - 1];
}

export function filterUndoActivities(activities) {
  let filteredUndoActivities = [];
  let undoEventActivities = activities.filter((activity) => activity.name === EventList.GetUndoEvent);

  if (undoEventActivities.lentgh === 0) {
    return activities;
  }

  let activitiesToBeHidden = getHiddenActivities(activities, undoEventActivities);
  let displayUndoKeyActivities = activities.filter(
    (activity, index) => activity.undokey && checkIfUserHasAnswered(activities, index),
  );

  activities.forEach((activity) => {
    if (activity.text !== EventList.UndoLastQuestion) {
      if (activitiesToBeHidden.indexOf(activity) === -1) {
        filteredUndoActivities.push(activity);
      }
    }
  });

  let displayUndoIconIndex = undoIconPosition(filteredUndoActivities);
  let activitiesWithoutEvents = activities.filter((activity) => activity.type !== 'event');

  let hasUndoIcon =
    activitiesWithoutEvents[activitiesWithoutEvents.length - 1]?.displayundoiconforundokey ===
    displayUndoKeyActivities[displayUndoKeyActivities.length - 1]?.undokey;
  displayUndoIconIndex = hasUndoIcon ? displayUndoIconIndex : -1;

  let messageWithUndoIconVisibleId = filteredUndoActivities[displayUndoIconIndex]?.id
    ? filteredUndoActivities[displayUndoIconIndex]?.id
    : '';

  return { filteredUndoActivities, messageWithUndoIconVisibleId };
}

function checkIfUserHasAnswered(filteredActivities, currentIndex) {
  let numberOfUserMessage = 0;
  filteredActivities.forEach((activity, index) => {
    if (
      index > currentIndex &&
      (activity.from.id === 'user' || activity.from.role === 'user') &&
      activity.type !== 'event'
    ) {
      if (activity.text !== 'UndoLastQuestion') numberOfUserMessage++;
      else {
        return numberOfUserMessage >= 1;
      }
    }
  });
  return numberOfUserMessage >= 1;
}

function undoIconPosition(activities) {
  let iconPosition = -1;
  let noOfActivities = activities.length - 1;

  while (noOfActivities > 0) {
    if (
      activities[noOfActivities].type !== 'event' &&
      (activities[noOfActivities].from.id === 'user' || activities[noOfActivities].from.role === 'user')
    ) {
      iconPosition = noOfActivities;
    }

    if (activities[noOfActivities].undokey) {
      if (iconPosition !== -1) {
        return iconPosition;
      }
    }

    noOfActivities--;
  }
  return -1;
}

function getHiddenActivities(activities, undoEventActivities) {
  let hiddenActivities = [];

  undoEventActivities.forEach((undoEvent) => {
    let index = activities.indexOf(undoEvent);
    let subArray = activities.slice(0, index);
    let subArrayUndoKey = subArray.filter(
      (activity) => activity.undokey && activity.undokey === undoEvent.value,
    );
    let lastSubArrayUndoKey = subArrayUndoKey[subArrayUndoKey.length - 1];
    let lastUndoKeyIndex = subArray.indexOf(lastSubArrayUndoKey);

    hiddenActivities = [...hiddenActivities, ...subArray.slice(lastUndoKeyIndex, index)];
  });
  return hiddenActivities;
}

function isAnswerModified(editAnswerEvents, activity) {
  if (activity.arrayKey) {
    return editAnswerEvents.some(
      ({ value }) =>
        (value.editableKey === activity.editableKey && value.arrayKey === activity.arrayKey) ||
        value.editableKey === activity.aggregatedkey,
    );
  }

  return editAnswerEvents.some(
    ({ value }) => value.editableKey === activity.editableKey || value.editableKey === activity.aggregatedkey,
  );
}

function getActivityLoopText(editAnswerEvents, activity) {
  const editAnswerEventsByArrayKey = editAnswerEvents.filter((x) => x.value.arrayKey === activity.arrayKey);

  if (isAnswerModified(editAnswerEventsByArrayKey, activity)) {
    const activityTextValidation = validateMessage(activity.text, activity.validationRegex);

    if (!activityTextValidation) {
      return getValueOrUndefined(activity, 'channelData', 'messageBack', 'displayText') || activity.text;
    }
    return editAnswerEventsByArrayKey[editAnswerEventsByArrayKey.length - 1].value.editValue;
  } else {
    return getValueOrUndefined(activity, 'channelData', 'messageBack', 'displayText') || activity.text;
  }
}

function validateMessage(value, validationRegex) {
  let validMessageRegex = validationRegex?.includes('\\p{L}')
    ? new RegExp(validationRegex, 'u')
    : new RegExp(validationRegex);

  return validMessageRegex.test(value);
}

export function getActivityText(activity, activities, isContinue) {
  // Check if activity is on continue
  if (activity.masktemplate && isContinue) {
    activity.text = maskWithTemplate(activity.masktemplate, activity.text);
  }

  // if activity can not be edited just get the value
  if (!activity.editableKey && !activity.aggregatedkey) {
    return getActivityTextWithEditDependencies(activities, activity);
  }

  // if is not last key then just get the value
  const editableActivities = activities.filter(
    (x) => x.type === EventList.Message && x.editableKey === activity.editableKey,
  );
  if (
    !activity.arrayKey &&
    editableActivities[editableActivities.length - 1] !== activity &&
    !activity.aggregatedkey
  ) {
    return getValueOrUndefined(activity, 'channelData', 'messageBack', 'displayText') || activity.text;
  }

  const editEvents = activities.filter((x) => x.name === EventList.EditAnswerEvent);
  const editAnswerEvents = editEvents.filter(
    (x) => x.value.editableKey === activity.editableKey || x.value.editableKey === activity.aggregatedkey,
  );

  // if activity was not edited just get the value
  if (editAnswerEvents.length === 0 && !activity.aggregatedkey) {
    return getValueOrUndefined(activity, 'channelData', 'messageBack', 'displayText') || activity.text;
  }

  // Check if activity is coming from a loop
  if (activity.arrayKey) {
    return getActivityLoopText(editAnswerEvents, activity);
  }

  // Check if activity is an aggregated key (two separated keys concatenated)
  // Or activity value has been edited
  // Or return activity text
  if (IsAggregatedAnswer(activity, editEvents)) {
    return getAggregatedValue(activity, activities);
  } else if (isAnswerModified(editAnswerEvents, activity)) {
    let lastModifiedAnswer = editAnswerEvents[editAnswerEvents.length - 1].value.editValue;

    if (activity.thankyoumessage) {
      if (isContinue) {
        lastModifiedAnswer = maskContent('name', lastModifiedAnswer);
      }
      return activity.istitleexcluded
        ? ExcludeTitleFromName(lastModifiedAnswer)
        : `${activity.thankyoumessage} ${lastModifiedAnswer}`;
    }
    return lastModifiedAnswer;
  } else {
    return getValueOrUndefined(activity, 'channelData', 'messageBack', 'displayText') || activity.text;
  }
}
export function getMaskedActivityState(isContinue, activityIndex, previousActivitiesCount){
  if(isContinue !== true){
    return false;
  } else if(activityIndex <= previousActivitiesCount){
    return true;
  } else if(previousActivitiesCount === 0) {
    return true;
  }else{
    return false;
  }
}

export function getFilteredActivities(filteredActivities, isContinue) {
  return filteredActivities.filter(({ type }) => type === 'message' || type === 'typing' || type === 'event')
    .filter(
      ({ channelData: { postBack } = {}, from: { role } }) => !(role === 'user' && postBack),
    )
    .map((activity) => ({
      ...activity,
      attachments: activity.attachments || [],
      text: getActivityText(activity, filteredActivities, isContinue),
      type: activity.type,
    }))
    .filter(({ attachments, text, type }) => attachments.length || text || type);
}

function getActivityTextWithEditDependencies(activities, activity) {
  const dependencies = activity.editdependencies;

  // if the activity does not have any edit dependencies just return the text value
  if (!dependencies) {
    return getValueOrUndefined(activity, 'channelData', 'messageBack', 'displayText') || activity.text;
  }

  let editTemplate = activity.edittemplate;
  dependencies.forEach((element) => {
    // Construct the string that should be replaced
    let replaceToken = '#' + element.token;
    // Search the last edit value for each element (type of edit)
    let displayedValue = getLastEditEventByEditType(
      activities,
      element.type,
      element.editableKey,
      element.arrayKey,
    );

    if (displayedValue) {
      // Update the edit template with latest value from the edit event
      editTemplate = editTemplate.replace(replaceToken, displayedValue.value.editValue);
    } else {
      // No edit was performed for this type, so keep the default value
      editTemplate = editTemplate.replace(replaceToken, element.defaultValue);
    }
  });

  // Return the newly formatted text
  return editTemplate;
}

function getAggregatedValue(activity, activities) {
  var aggregatedKeys = activity.aggregatedkey.split(',');
  let lastModifiedAnswer = '';

  aggregatedKeys.forEach((element) => {
    let component = activities.filter(
      (x) =>
        (x.name === EventList.EditAnswerEvent && x.value.editableKey === element) ||
        x.editableKey === element,
    );

    if (component.length === 1) {
      lastModifiedAnswer = lastModifiedAnswer + ' ' + component[0].value;
    } else {
      if (component.length > 1) {
        lastModifiedAnswer = lastModifiedAnswer + ' ' + component[component.length - 1].value.editValue;
      }
    }
  });

  if (activity.textaggregatedtemplate) {
    return activity.textaggregatedtemplate.replace('{aggregatedValue}', lastModifiedAnswer);
  }

  if (activity.thankyoumessage) {
    return `${activity.thankyoumessage} ${lastModifiedAnswer}`;
  }
  return lastModifiedAnswer;
}

export function getImageLocationUrl(activity, activities) {
  if (!activity.value.editableKey) {
    return activity.value.contentUrl;
  }

  const editEventsImageMap = activities.filter(
    (x) => x.name === EventList.EditAnswerEvent && x.value.editableKey === activity.value.editableKey,
  );

  if (editEventsImageMap.length === 0) {
    return activity.value.contentUrl;
  }

  return editEventsImageMap.pop().value.locationImage;
}

function IsAggregatedAnswer(activity, editEvents) {
  let lastEventActivityValue = editEvents[editEvents.length - 1]?.value.editableKey;
  var aggregatedKeys = activity.aggregatedkey?.split(',');

  if (editEvents.length === 0 || !activity.aggregatedkey || aggregatedKeys.length < 2) {
    return false;
  }

  return aggregatedKeys.filter((x) => x == lastEventActivityValue).length;
}

function ExcludeTitleFromName(userName) {
  const nameTitles = ['mr', 'mrs', 'miss', 'ms', 'dr', 'sir', 'lady'];
  const hasTitle = nameTitles.some((value) => value === userName.toLowerCase().split(' ')[0]);
  if (hasTitle) {
    return userName.substr(userName.indexOf(' ') + 1);
  }
}

export function maskWithTemplate(template, text) {
  const openIndex = template.indexOf('{');
  const closeIndex = template.indexOf('}');

  if (openIndex === -1 || closeIndex === -1) {
    throw new Error('MaskTemplate is invalid');
  }

  const startPart = template.substring(0, openIndex);
  const maskType = template.substring(openIndex + 1, closeIndex);
  const endPart = closeIndex === template.length - 1 ? null : template.substring(closeIndex + 1);

  const content = text.replace(startPart, '').replace(endPart, '').trim();

  return `${startPart} ${maskContent(maskType, content)}` + (endPart ? ` ${endPart}` : '');
}

export function getLastEditEventByEditType(activities, editType, editableKey, arrayKey = null) {
  // get all the edit events
  const editEvents = activities.filter((x) => x.name === EventList.EditAnswerEvent);
  // filter the edit events by their type
  const editTypeEvents = editEvents.filter((x) => x.value.editType === editType);
  // filter the same type of edit events by their editable key
  const editTypeEventsByEditableKey = editTypeEvents.filter((x) => x.value.editableKey === editableKey);

  if (arrayKey) {
    // special case for edit on loops
    const editTypeEventsByArrayKey = editTypeEvents.filter((x) => x.value.arrayKey === arrayKey);
    return editTypeEventsByArrayKey.pop();
  }

  // return the last one
  return editTypeEventsByEditableKey.pop();
}

export function maskContent(maskType, content) {
  const secretChar = '*';

  // mask content based on the string length
  const maskValue = (value) => {
	const valueLength = value.length;
	const secretCharNumber = valueLength > 6 ? valueLength-3 : Math.floor(valueLength/2)
	return value.substring(0, value.length-secretCharNumber) + secretChar.repeat(secretCharNumber);
  };

  const maskAddress = (address) => {
    const fields = address.split(',');
    const maskedFields = fields.map((x) => maskValue(x.trim()));
    return maskedFields.join(', ');
  };

  const maskFirstNameAndSurname = (firstNameAndSurname) => {
    const replacedFirstNameAndSurname = firstNameAndSurname.split(' ');
    const maskedFirstNameandSurname = replacedFirstNameAndSurname.map((x) => maskValue(x.trim()));
    return maskedFirstNameandSurname.join(' ');
  };

  switch (maskType) {
    case 'name':
      return maskValue(content);
    case 'email':
      return maskValue(content);
    case 'phone':
      return maskValue(content);
    case 'houseNumber':
      return maskValue(content);
    case 'policyNumber':
      return maskValue(content);
    case 'postcode':
      return maskValue(content);
    case 'iban':
      return maskValue(content)
    case 'driversLicence':
      return maskValue(content);
    case 'homeAddress':
      return maskAddress(content);
    case 'firstnameAndSurname':
      return maskFirstNameAndSurname(content);
    default:
      throw new Error(`Invalid maskType '${maskType}'`);
  }
}

export default {
  getLastActivityByType,
  getLastActivityByName,
  getActivityText,
  getFilteredActivities,
  filterUndoActivities,
  getMaskedActivityState,
};
