import React, { useRef, useState, useEffect } from 'react';
import { hooks } from 'botframework-webchat-component';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { colorPalette } from 'ri-components';
import { EventList } from '../utils/EventList';
import { getLastActivityByName } from '../utils/activity-utils';
import { useClaimFile } from '../../../services/ClaimFileContextProvider';

const { useSendEvent, useActivities } = hooks;
const { white, black } = colorPalette;

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    position: 'absolute',
    top: theme.spacing(0.25),
    right: theme.spacing(0.25),
    padding: 0,
    height: 20,
    width: 20,
    color: white,
    backgroundColor: black,
    opacity: 0.8,
    '&:hover': {
      backgroundColor: black,
      opacity: 0.8,
    },
    '&:focus': {
      outline: 'auto',
    },
  },
  icon: {
    height: 18,
    width: 18,
  },
}));

const DeleteAttachmentButton = ({ name, activityId, fileId }) => {
  const classes = useStyles();

  const sendEvent = useSendEvent();
  const [activities] = useActivities();

  const deleteButtonRef = useRef(null);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [stepFinished, setStepFinished] = useState(false);
  const claimStepFiles = useClaimFile();

  // SetFilePropertiesEvent is sent in order to show/hide the delete icon from the file.
  // When the value of the event is false the step is considered finished,
  // and the delete icon will not be shown when the photo component will be rerendered
  const filePropertiesActivity = getLastActivityByName(activities, EventList.SetFilePropertiesEvent);

  useEffect(() => {
    if (filePropertiesActivity) {
      const properties = JSON.parse(filePropertiesActivity.value);
      setShowDeleteIcon(!!properties.canDeleteFiles);
      if (properties.canDeleteFiles === false) {
        setStepFinished(true);
      }
    }
  }, [filePropertiesActivity]);

  function removeFileFromState(fileId) {
    claimStepFiles.current = claimStepFiles.current.map((stepFiles) => {
      return {
        stepKey: stepFiles.stepKey,
        files: stepFiles.files.filter((x) => x.id != fileId),
      };
    });
  }

  const handlePhotoDelete = () => {
    removeFileFromState(fileId);
    const filesToDelete = [
      {
        name,
        fileId,
        activityId,
      },
    ];
    sendEvent(EventList.RemoveFileEvent, JSON.stringify(filesToDelete));
    deleteButtonRef.current.blur();
  };

  return (
    !stepFinished &&
    showDeleteIcon && (
      <IconButton
        ref={deleteButtonRef}
        disableRipple
        className={`${classes.deleteButton} delete-file-button`}
        onClick={handlePhotoDelete}
        tabIndex='0'
      >
        <ClearIcon className={classes.icon} />
      </IconButton>
    )
  );
};

DeleteAttachmentButton.propTypes = {
  name: PropTypes.string.isRequired,
  fileId: PropTypes.string.isRequired,
  activityId: PropTypes.string,
};

export default DeleteAttachmentButton;
