import React, { useState } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import PropTypes from 'prop-types';
import ClaimButton from '../../../../components/ClaimButton';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import clsx from 'clsx';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Typography } from '@material-ui/core';
import { useStyles } from '../../suggested-actions/contact-number-provider/ContactNumberProvider';
import { hooks } from 'botframework-webchat-component';
import { EventList } from '../../utils/EventList';
const { useSendEvent } = hooks;

const EditModalContactNumberProvider = ({
  children,
  editLabels,
  openModalOverlay,
  setOpenModalOverlay,
  editableKey,
  editType,
  arrayIndexKey,
  arrayKey,
}) => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const [number, setNumber] = useState(children);
  const [isValid, setIsValid] = useState(isPhoneValid(children));
  const [confirmPressed, setConfirmPressed] = useState(false);
  const modalLabels = editLabels;
  const classes = useStyles();
  const sendEvent = useSendEvent();

  const onConfirm = (phoneNumber) => {
    setConfirmPressed(true);

    if (isValid) {
      sendEvent(EventList.EditAnswerEvent, {
        editableKey: editableKey,
        editValue: phoneNumber,
        arrayKey: arrayKey,
        arrayIndexKey: arrayIndexKey,
        editType: editType,
      });
      setOpenModalOverlay(false);
    }
  };

  return (
    <div style={{ display: !openModalOverlay ? 'none' : '' }}>
      <ModalOverlay className={classes.modalOverlay} open={true} onClose={() => setOpenModalOverlay(false)}>
        <ModalOverlayTitle style={{ marginBottom: '-28px' }}>{modalLabels.header}</ModalOverlayTitle>
        <ModalOverlayBody>
          {modalLabels.helpLabel && <Typography className={classes.helpLabel}>{modalLabels.helpLabel}</Typography>}
          <div className={classes.inputContainer}>
            <PhoneInput
              className={clsx(classes.phoneInputContainer)}
              inputClassName={clsx(classes.inputField)}
              countrySelectorStyleProps={{
                buttonClassName: clsx(classes.countrySelector),
                dropdownStyleProps: {
                  className: clsx(classes.countriesContainer),
                  listItemClassName: clsx(classes.countryListItem),
                },
              }}
              value={number}
              onChange={(phone) => {
                setNumber(phone);
                setIsValid(isPhoneValid(phone));
              }}
            />
          </div>
          {!isValid && confirmPressed && <Typography className={classes.errorMessage}>{modalLabels.errorMessage}</Typography>}
          <ClaimButton
            handleClick={() => onConfirm(number)}
            label={modalLabels.confirmButton}
            externalClassName='external-modal-button'
          />
        </ModalOverlayBody>
      </ModalOverlay>
    </div>
  );
};

EditModalContactNumberProvider.propTypes = {
  children: PropTypes.node,
  editableKey: PropTypes.string,
  arrayKey: PropTypes.string,
  arrayIndexKey: PropTypes.number,
  editLabels: PropTypes.object,
  editType: PropTypes.string,
  setOpenModalOverlay: PropTypes.func,
  openModalOverlay: PropTypes.bool,
};

export default EditModalContactNumberProvider;
