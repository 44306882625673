import axios from 'axios';

export const uploadFiles = async (webApiUrl, webApiToken, files, acceptedTypes, fileConversationId, sync) => {
  let promises = [];
  let data = {
    savedFiles: [],
    unsavedFiles: [],
  };

  files.forEach((file) => {
    if (!isValidExtension(file, acceptedTypes) && !isValidFileType(file, acceptedTypes)) {
      data.unsavedFiles.push({
        name: file.name,
        extension: file.type,
      });
      return;
    }

    promises.push(
        saveFile(webApiUrl, webApiToken, file, fileConversationId, sync)
        .then((response) => {
          data.savedFiles.push({
            contentType: response.data.contentType,
            name: response.data.name,
            fileName: response.data.fileName,
            id: response.data.id,
            fileExtension: response.data.extension,
            webApiToken: webApiToken,
            statusCode: response.data.statusCode
          });
        })
        .catch((error) => {
          data.unsavedFiles.push({
            name: file.name,
              extension: file.type,
              statusCode: error.response.status
          });
        }),
    );
  });

  return Promise.all(promises).then(() => {
    return { data };
  });
};

function getFileNameWithLowerCaseExtension(fileName) {
  const fileNameSplitted = fileName.split('.');
  fileNameSplitted[fileNameSplitted.length - 1] = fileNameSplitted[fileNameSplitted.length - 1].toLowerCase();
  return fileNameSplitted.join('.');
}

function getFileExtension(file) {
  return file.name.split('.').pop().toLowerCase();
}

function isValidExtension(file, acceptedTypes) {
  return acceptedTypes.includes(getFileExtension(file));
}

function isValidFileType(file, acceptedTypes) {
  return acceptedTypes.includes(file.type);
}

function saveFile(webApiUrl, webApiToken, file, fileConversationId, sync) {
  const options = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + webApiToken,
    },
    onUploadProgress: (progressEvent) => {
      let percent = Math.floor((progressEvent.loaded * 100) / file.size);
      file.loadedPercentage = percent;
    },
  };

  let formData = new FormData();
  formData.append('file', file, getFileNameWithLowerCaseExtension(file.name));
  formData.append('fileConversationId', fileConversationId);
  formData.append('sync', sync);

  return axios.post(`${webApiUrl}/api/files/save-file`, formData, options);
}
