import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { cleanCookiesState } from '../Oauth0';

export const CallBackLogout = () => {
  const history = useHistory();

  useEffect(() => {
    const oauthCookies = JSON.parse(Cookies.get('oauth'));
    cleanCookiesState();
    history.push(`/continue/${oauthCookies.resumeCodeId}?lang=${oauthCookies.language}`);
  }, [history]);

  return null;
};
