import React from 'react';
import { BoxScreenCenter } from '../../../components/LoadingSuspense';
import ClaimButton from '../../../components/ClaimButton';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core';
import { useOauthApiConfigs } from '../../../services/TenantOauthContextProvider';

const useStyles = makeStyles(() => ({
  resumeMessage: {
    fontSize: '17px',
  },
  resumeBtn: {
    marginTop: '20px',
  },
}));

export const JourneyInitiatorError = () => {
  const classes = useStyles();
  const oauthConfigs = useOauthApiConfigs();
  const oauthCookies = JSON.parse(Cookies.get('oauth'));
  const invalidResumeLinkMessage = {
    en: <p>Invalid resume link</p>,
  };

  return (
    <BoxScreenCenter>
      <div className={classes.resumeMessage}>{invalidResumeLinkMessage[oauthCookies.language]}</div>
      <ClaimButton
        handleClick={() => {
          const logoutUrl = `https://${oauthConfigs.domain}/v2/logout?client_id=${oauthConfigs.clientId}&returnTo=${encodeURIComponent(oauthConfigs.logoutEndpoint)}`;
          window.location.href = logoutUrl;
        }}
        className={classes.resumeBtn}
        label='Redirect to login'
      />
    </BoxScreenCenter>
  );
};
