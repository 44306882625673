import { makeStyles } from '@material-ui/core';

const useHideScrollBarStyles = makeStyles({
  hideScrollBar: {
    '-ms-overflow-style': 'none' /* IE and Edge */,
    'scrollbar-width': 'none' /* Firefox */,

    '&::-webkit-scrollbar': {
      /* Hide scrollbar for Chrome, Safari and Opera */ display: 'none',
    },
  },
});

export default useHideScrollBarStyles;
