import RiGoogleMaps from './RiGoogleMaps';
import React, { useState, useCallback, useEffect } from 'react';
import AutocompleteInput from './AutocompleteInput';
import ClaimButton from '../../../../components/ClaimButton';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MD_LAYOUT_BREAKPOINT } from '../../../../themes/GlobalThemeProvider';
import useAccessibility from '../../hooks/useAccessibility';

const useStyles = makeStyles((theme) => ({
  selectLocationButton: {
    margin: '20px',
  },
  containerElement: {
    height: `400px`,
    marginBottom: '20px',
    width: '100%',
    padding: '0 20px',
    [theme.breakpoints.down(MD_LAYOUT_BREAKPOINT)]: {
      height: '240px',
      padding: '0px',
    },
  },
  loadingElement: { height: `100%` },
  mapElement: { height: `100%` },
  mapContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    marginTop: '-28px',
    width: '100%',
    [theme.breakpoints.between(0, 350)]: {
      maxWidth: '240px',
    },
    [theme.breakpoints.between(350, 420)]: {
      maxWidth: '290px',
    },
  },
}));

const AutocompleteMaps = ({ onLocationSelected, mapLabels, initialAddress }) => {
  let mapDefaultValues;
  if (mapLabels.zoom && mapLabels.lat && mapLabels.lng) {
    mapDefaultValues = {
      coordinates: {
        lat: mapLabels.lat,
        lng: mapLabels.lng,
      },
      zoom: mapLabels.zoom,
    };
  }

  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [shortAddress, setShortAddress] = useState('');
  const [postcode, setPostcode] = useState('');
  const [foundPlaces, setFoundPlaces] = useState(false);
  const [pintLatAndLong, setPintLatAndLong] = useState('');
  const [administrativeArea, setAdministrativeArea] = useState('');
  const [isFromInput, setIsFromInput] = useState(false);
  const [submitButtonState, setSubmitButtonState] = useState(false);
  const classes = useStyles();
  const [isMapAdjusted, setIsMapAdjusted] = useState(false);

  

  const onValueChangedHandler = useCallback(
    (address, postcode, country, shortAddress, administrativeArea) => {
      setIsFromInput(true);
      setAddress(address);
      setPostcode(postcode);
      setCountry(country);
      setShortAddress(shortAddress);
      setAdministrativeArea(administrativeArea);
    },
    [],
  );

  const onMapChangedHandler = (shortAddress ,address, postcode, country, administrativeArea) => {
    setIsFromInput(false);
    setShortAddress(shortAddress);
    setAddress(address);
    setPostcode(postcode);
    setFoundPlaces(true);
    setCountry(country);
    setAdministrativeArea(administrativeArea);
  };

  const onMapAdjustedHandler = () => {
    setIsMapAdjusted(true);
  };

  const onLatAndLogChangedHandler = (pintLatAndLong) => {
    setPintLatAndLong(pintLatAndLong);
  };

  const foundPlacesHandler = useCallback((found) => {
    setFoundPlaces(found);
  }, []);

  const onLocationSelectedHandler = () => {
    onLocationSelected({
      address: address,
      foundByGoogle: foundPlaces,
      pinLatAndLong: pintLatAndLong,
      postcode,
      country: country,
      shortAddress: shortAddress,
      administrativeArea: administrativeArea,
    });
  };

  const { addressInput } = useAccessibility();

  return (
    <div className={classes.container}>
      <div className={classes.input}>
        <AutocompleteInput
          selectLocation={onLocationSelectedHandler}
          sendBoxValue={address}
          initialAddress={initialAddress}
          placeholderText={mapLabels.placeHolder}
          onValueChange={onValueChangedHandler}
          onFoundPlaces={foundPlacesHandler}
          foundPlaces={foundPlaces}
          notFoundLabel1={mapLabels.notFoundLabel1}
        />
      </div>
      {
        <div className={classes.mapContainer}>
          <RiGoogleMaps
            address={address}
            initialAddress={initialAddress}
            onMapChanged={onMapChangedHandler}
            onMapAdjusted={onMapAdjustedHandler}
            isFromInput={isFromInput}
            onLatAndLogChanged={onLatAndLogChangedHandler}
            showCenterMarker
            loadingElement={<div className={classes.loadingElement} />}
            containerElement={<div className={classes.containerElement} />}
            mapElement={<div className={classes.mapElement} />}
            aria-label={addressInput}
            mapDefaultValues={mapDefaultValues}
            setIsMapAdjusted={onMapAdjustedHandler}
          />
        </div>
      }
      <ClaimButton
        handleClick={onLocationSelectedHandler}
        disabled={ !foundPlaces }
        className={`${classes.selectLocationButton}`}
        label={mapLabels.selectButton}
      />
    </div>
  );
};

AutocompleteMaps.propTypes = {
  onLocationSelected: PropTypes.func,
  mapLabels: PropTypes.shape({
    placeHolder: PropTypes.string.isRequired,
    notFoundLabel1: PropTypes.string.isRequired,
    selectButton: PropTypes.string.isRequired,
    lat: PropTypes.number,
    zoom: PropTypes.number,
    lng: PropTypes.number,
  }),
  initialAddress: PropTypes.any,
  mapDefaultValues: PropTypes.any,
  setIsMapAdjusted: PropTypes.func,
};

export default AutocompleteMaps;
