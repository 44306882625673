/* eslint-disable no-undef */

import React, { useRef, useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Grid, Typography } from '@material-ui/core';
import { MD_LAYOUT_BREAKPOINT } from '../../../../themes/GlobalThemeProvider';
import useAccessibility from '../../hooks/useAccessibility';
import { colorPalette } from 'ri-components';
import { getCountry, getPostcode, getAdministrativeArea, getShortAddress } from './GoogleMapsHelper';

const { white } = colorPalette;

const StyledIconButton = withStyles((theme) => ({
  root: {
    height: '32px',
    width: '32px',
    color: white,
    padding: '0px',
    backgroundColor: theme.palette.primary.main,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      color: white,
      backgroundColor: '#999b9f',
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(IconButton);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1.25, 0, 2.5, 0),
    [theme.breakpoints.up(MD_LAYOUT_BREAKPOINT)]: {
      padding: theme.spacing(2.5),
    },
  },
  noLocationsFoundContainer: {
    justifyContent: 'center',
  },
  sendBox: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '24px',
    maxWidth: '720px',
    padding: theme.spacing(0.875, 1, 0.875, 2),
    border: 'solid 1px #dadce1',
  },
  sendBoxDefaultBackgroundColor: {
    backgroundColor: white,
  },
  sendBoxDisabledBackgroundColor: {
    backgroundColor: '#f2f3f4',
  },
  sendBoxInput: {
    width: '100%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    background: 'transparent',
    minHeight: '20px',
    maxHeight: '120px',
    fontSize: '18px',
    letterSpacing: '-0.2px',
    padding: '0px',
  },
  iconButton: {
    alignSelf: 'flex-end',
  },
  notFoundLabel1: {
    fontSize: '16px',
    color: '#D91A21',
    paddingTop: theme.spacing(0.375), // 6px padding to the top
  },
}));

const AutocompleteInput = ({
  placeholderText,
  onValueChange,
  sendBoxValue,
  onFoundPlaces,
  notFoundLabel1,
  initialAddress,
}) => {
  const classes = useStyles();

  const service = useRef(new google.maps.places.AutocompleteService());

  const inputRef = useRef();

  const searchBox = useRef();

  const geocoder = useRef();

  const [autocompleteAddress, setAutocompleteAddress] = useState('');
  const [placesReturned, setPlacesReturned] = useState(true);
  const [firstPrediction, setFirstPrediction] = useState('');
  const [isInputFocused, setIsInputFocused] = useState(false);

  useEffect(() => {
    setAutocompleteAddress(sendBoxValue);
  }, [sendBoxValue]);

  const getAddressFromPlace = (place) => {
      const geo = place.geometry.location;
    
    return {
      latitude: geo.lat(),
      longitude: geo.lng(),
      address: place.formatted_address,
      postcode: getPostcode(place),
      country: getCountry(place),
      administrativeArea: getAdministrativeArea(place),
        shortAddress: getShortAddress(place),
    };
  };

  useEffect(() => {
    function onPlaceSearched() {
      const places = searchBox.current.getPlaces();
      if (places.length > 0) {
        const place = getAddressFromPlace(places[0]);
        onValueChange(
          place.address,
          place.postcode,
          place.country,
          place.shortAddress,
          place.administrativeArea,
        );
        onFoundPlaces(true);
      }
    }

    geocoder.current = new google.maps.Geocoder();

    if (inputRef.current) {
      searchBox.current = new google.maps.places.SearchBox(inputRef.current);

      searchBox.current.addListener('places_changed', onPlaceSearched);
    }
  }, [onFoundPlaces, onValueChange]);

  useEffect(() => {
    if (autocompleteAddress === '') {
      onFoundPlaces(false);
      onValueChange(autocompleteAddress);
      setPlacesReturned(true);
      return;
    }

    const timeOut = setTimeout(() => {
      service.current.getQueryPredictions({ input: autocompleteAddress }, (prediction, status) => {
        if (status === 'ZERO_RESULTS' || status === 'OVER_QUERY_LIMIT') {
          setPlacesReturned(false);
          onFoundPlaces(false);
          onValueChange(autocompleteAddress);
        } else {
          setPlacesReturned(true);
          setFirstPrediction(prediction[0].description);
        }
      });
    }, 300);
    return () => {
      clearTimeout(timeOut);
    };
  }, [autocompleteAddress, onFoundPlaces, onValueChange, initialAddress]);

  useEffect(() => {
    if (initialAddress && initialAddress !== '') {
      setAutocompleteAddress(initialAddress);
      onValueChange(initialAddress);
      onFoundPlaces(false);
    }
  }, [setAutocompleteAddress, onValueChange, onFoundPlaces, initialAddress]);

  const onLocationSearch = (ev) => {
    setAutocompleteAddress(ev.target.value);
  };

  const selectTypedLocationHandler = () => {
    onValueChange(firstPrediction);
    onFoundPlaces(false);
  };

  const { addressInput } = useAccessibility();

  return (
    <div className={classes.root}>
      <div
        id='google-map-input-wrapper'
        className={
          classes.sendBox +
          (isInputFocused ? ' google-map-input-focused-wrapper' : '' + ' modal-input-answer')
        }
      >
        <input
          ref={inputRef}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
          autoFocus
          tabIndex='0'
          placeholder={placeholderText}
          className={`${classes.sendBoxInput}`}
          value={autocompleteAddress ?? ''}
          onChange={onLocationSearch}
          aria-label={addressInput}
        />
        {/*<StyledIconButton*/}
        {/*  onClick={selectTypedLocationHandler}*/}
        {/*  className={classes.iconButton + ' modal-send-button'}*/}
        {/*  disabled={autocompleteAddress === '' || !placesReturned}*/}
        {/*  disableRipple*/}
        {/*  aria-label='Send'*/}
        {/*>*/}
        {/*  <ArrowForwardIcon />*/}
        {/*</StyledIconButton>*/}
      </div>

      {!placesReturned && autocompleteAddress !== '' && (
        <Grid container className={classes.noLocationsFoundContainer}>
          <Typography variant='subtitle1' className={classes.notFoundLabel1}>
            &apos;{autocompleteAddress}&apos; {notFoundLabel1}
          </Typography>
        </Grid>
      )}
    </div>
  );
};

AutocompleteInput.propTypes = {
  placeholderText: PropTypes.string,
  onValueChange: PropTypes.func,
  sendBoxValue: PropTypes.string,
  onFoundPlaces: PropTypes.func,
  foundPlaces: PropTypes.bool,
  notFoundLabel1: PropTypes.string,
  initialAddress: PropTypes.string,
};

export default AutocompleteInput;
