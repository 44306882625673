import React, { useCallback, useState } from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { hooks } from 'botframework-webchat-component';
import { EventList } from '../../utils/EventList';
import { getLastEditEventByEditType } from '../../utils/activity-utils';
import { EditTypes } from '../../utils/EditTypes';
import { localeMap } from '../../utils/LocaleMap';
import { DateFormats } from '../../../../utils/dateUtils';

const customTheme = createTheme({
  overrides: {
    MuiPickersModal: {
      dialogRoot: {
        '@media (prefers-contrast: more)': {
          border: '3px solid rgba(0,0,0,0.0)',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#575757',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: 'black',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: 'white',
      },
    },
    MuiButton: {
      textPrimary: {
        color: 'black',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: 'black',
        '&:hover': {
          backgroundColor: 'black',
        },
        '&:focus': {
          backgroundColor: 'black',
        },
      },
      day: {
        color: 'black',
      },
      current: {
        color: 'black',
      },
      dayDisabled: {
        color: 'black',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: 'black',
      },
    },
  },
});

const { useSendEvent, useActivities } = hooks;

const getValidTime = (currentHour, selectedHour, currentMinutes, selectedMinutes) => {
  if (currentHour < selectedHour) {
    return false;
  } else if (currentHour === selectedHour && currentMinutes < selectedMinutes) {
    return false;
  }

  return true;
};

const EditModalDatePicker = ({
  value,
  children,
  openModalOverlay,
  setOpenModalOverlay,
  editableKey,
  editType,
  timeKey,
  arrayIndexKey,
  arrayKey,
  utcDateTimeProp,
}) => {
  const sendEvent = useSendEvent();

  const [activities] = useActivities();
  let minDate = new Date('1900-01-01');
  let maxDate = new Date();
  minDate = value?.mindate;
  const [locale] = useState(!value?.language || value?.language === 'en' ? localeMap.en : value?.language);
  moment.locale(locale);

  const editedTime = getLastEditEventByEditType(activities, EditTypes.Time, timeKey);
  let selectedTime;
  if (!editedTime) {
    // No edit was performed on time picker, we use the initial selected time
    const messageActivities = activities.filter((x) => x.type === EventList.Message);
    const timeActivity = messageActivities.filter((x) => x?.editType === EditTypes.Time)?.pop();
    selectedTime = timeActivity?.value;
  } else {
    // Edit on time was performed, use the last edit value
    selectedTime = editedTime?.value?.editValue;
  }

  const currentHour = maxDate.getHours();
  const currentMinutes = maxDate.getMinutes();
  const timeArray = selectedTime?.split(':');
  const selectedHour = Number(timeArray?.at(0));
  const selectedMinutes = Number(timeArray?.at(1));

  const handleSubmit = useCallback(
    (date) => {
      sendEvent(EventList.EditAnswerEvent, {
        editableKey: editableKey,
        editValue: moment(date).format(DateFormats.displayFormat),
        arrayKey: arrayKey,
        arrayIndexKey: arrayIndexKey,
        editType: editType,
        utcDateTimeProp: utcDateTimeProp,
      });
    },
    [sendEvent, editableKey, arrayKey, arrayIndexKey, editType, utcDateTimeProp],
  );

  let selectedDate = children === 'Yesterday' || children === 'Today' ? new Date() : new Date(children);
  if (children === 'Yesterday') {
    selectedDate.setDate(selectedDate.getDate() - 1);
  }
  selectedDate.setHours(0, 0, 0, 0);

  if (selectedDate < maxDate && !getValidTime(currentHour, selectedHour, currentMinutes, selectedMinutes)) {
    maxDate.setDate(maxDate.getDate() - 1);
  }
  maxDate.setHours(0, 0, 0, 0);

  return (
    <>
      <div style={{ display: 'none' }} data-testid='date-picker-wrapper'>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <ThemeProvider theme={customTheme}>
            <DatePicker
              open={openModalOverlay}
              onClose={() => {
                setOpenModalOverlay(false);
              }}
              onChange={handleSubmit}
              disableFuture
              value={selectedDate}
              minDate={minDate ?? undefined}
              maxDate={maxDate ?? undefined}
              format={DateFormats.displayFormat}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
    </>
  );
};

export default EditModalDatePicker;

EditModalDatePicker.propTypes = {
  children: PropTypes.node,
  editableKey: PropTypes.string,
  arrayKey: PropTypes.string,
  arrayIndexKey: PropTypes.number,
  editType: PropTypes.string,
  timeKey: PropTypes.string,
  value: PropTypes.any,
  setOpenModalOverlay: PropTypes.func,
  openModalOverlay: PropTypes.bool,
  utcDateTimeProp: PropTypes.string,
};
