import React, { memo } from 'react';

type Props = Readonly<{ className?: string }>;

const ThumbLike16Regular = memo(({ className }: Props) => (
  <svg className={className} fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.03494 1.92332C8.24411 1.40248 8.83006 0.843 9.5806 1.0516C10.1709 1.21566 10.5544 1.57258 10.7735 2.04807C10.9778 2.49126 11.028 3.01293 11.0375 3.51008C11.0478 4.04484 10.9361 4.68641 10.8047 5.24751C10.7434 5.50906 10.6759 5.76117 10.6099 5.9878H11.9949C13.3298 5.9878 14.29 7.2705 13.9139 8.55127L12.5497 13.1973C12.1555 14.5397 10.7365 15.2974 9.40179 14.8781L4.04591 13.1958C3.45891 13.0114 2.98944 12.5671 2.77304 11.9912L2.25304 10.6072C1.91142 9.69797 2.27244 8.67473 3.109 8.18119L4.98089 7.07684C4.98462 7.07423 4.99002 7.07037 4.99704 7.0652C5.01761 7.05005 5.05204 7.02362 5.09835 6.98435C5.19093 6.90585 5.33128 6.77579 5.5035 6.58154C5.84755 6.19347 6.32141 5.54625 6.79565 4.53704C7.00052 4.10106 7.17287 3.75833 7.32941 3.44707C7.58243 2.94395 7.79411 2.52303 8.03494 1.92332ZM5.51946 7.91969C5.51378 7.92334 5.50805 7.9269 5.50224 7.93032L3.61713 9.04247C3.19885 9.28924 3.01834 9.80086 3.18915 10.2555L3.70914 11.6394C3.81735 11.9274 4.05208 12.1496 4.34558 12.2418L9.70146 13.9241C10.5023 14.1756 11.3537 13.721 11.5902 12.9156L12.9544 8.26953C13.1424 7.62915 12.6623 6.9878 11.9949 6.9878H9.92587C9.76349 6.9878 9.61122 6.90894 9.51751 6.77632C9.42381 6.64369 9.40035 6.47382 9.4546 6.32077C9.55458 6.03869 9.70672 5.54998 9.83103 5.01941C9.95711 4.48125 10.0456 3.93709 10.0377 3.52931C10.0287 3.061 9.97907 2.71335 9.86536 2.46667C9.76654 2.25227 9.61384 2.09874 9.31282 2.01508C9.26971 2.0031 9.21948 2.0066 9.15353 2.04989C9.08149 2.09718 9.00781 2.18417 8.96291 2.29598C8.70922 2.92772 8.46651 3.41157 8.19787 3.94711C8.04311 4.25564 7.87974 4.58133 7.7007 4.96233C7.18681 6.05594 6.66067 6.78372 6.25176 7.24494C6.04749 7.47534 5.87312 7.6385 5.74509 7.74707C5.68109 7.80133 5.62875 7.84188 5.59013 7.87034C5.57082 7.88456 5.55495 7.89575 5.54277 7.90411L5.5273 7.91456L5.52171 7.91823L5.51946 7.91969ZM4.97612 7.08018L4.97492 7.08097L4.97612 7.08018Z"
      fill="currentcolor"
    />
  </svg>
));

ThumbLike16Regular.displayName = 'ThumbLike16Regular';

export default ThumbLike16Regular;
