/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import './googleStyles.css';
import ClaimButton from '../../../../components/ClaimButton';

const useStyles = makeStyles(() => ({
  modalWrapper: {
    overflow: 'hidden !important',
  },
  modalTitle: {
    fontWeight: 900,
    fontSize: 18,
    color: 'black',
    marginBottom: '10px',
    textDecoration: 'underline',
  },
  modalItem: {
    marginBottom: '2px',
    fontSize: 14,
    fontWeight: 500,
    color: 'black',
    textAlign: 'left',
  },
  modalButtonStyle: {
    padding: '4px 15px',
    outline: 'none !important',
    width: 'unset !important',
    marginBottom: '2px',
  },
  modalButtonContainer: {
    paddingTop: 4,
  },
  markerLabel: {
    textShadow: [
      '-1px -1px 0 #FFF',
      '1px -1px 0 #FFF',
      '-1px 1px 0 #FFF',
      '1px 1px 0 #FFF']

  },
  markerLabelSatellite: {
    textShadow: [
      '-2px -2px 0 #000',
      '2px -2px 0 #000',
      '-2px 2px 0 #000',
      '2px 2px 0 #000']
  }
}));

const getDistance = (distance, unit) => `${distance.toFixed(2)} ${unit}`;

const GoogleMapsWithMarkers = withGoogleMap((props) => {
  const classes = useStyles();
  const [latLng, setLatLng] = useState({
    lat: props.markers[0].latitude,
    lng: props.markers[0].longitude,
  });
  const [markerLabelStyle, setMarkerLabelStyle] = useState({
    color: '#000000',
    fontSize: '14px',
    className: classes.markerLabel,
    fontWeight: 'bold'
  })

  const mapRef = useRef();

  const [activeMarker, setActiveMarker] = useState(props.markers[0].id);
  const info = [];

  const getMapModalTitle = (value) => {
    info.push(value);
    return (
      <Grid item className={classes.modalTitle}>
        {value}
      </Grid>
    );
  };

  const getMapModalRow = (label, value) => {
    info.push(value);
    return (
      <Grid item container direction='row' spacing={1} className={classes.modalItem}>
        <Grid item>{label}</Grid>
        <Grid item>{value}</Grid>
      </Grid>
    );
  };

  const getMapModalMultiValueRow = (label, valueArray) => {
    info.push(valueArray.join(', '));
    return (
      <Grid item container direction='row' spacing={1} className={classes.modalItem}>
        <Grid item>{label}</Grid>
        <Grid item>
          {valueArray.map((value, index) => (
            <React.Fragment key={index}>
              {value}
              {index < valueArray.length - 1 && ", "}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <GoogleMap
      zoom={12}
      defaultCenter={latLng}
      options={{
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        zoomControl: true,
        scrollwheel: true,
        clickableIcons: false,
        gestureHandling: 'auto',
      }}
      ref={mapRef}
      onMapTypeIdChanged={() => {
        const mapTypeId = mapRef.current.getMapTypeId()
        if (mapTypeId == google.maps.MapTypeId.TERRAIN || mapTypeId == google.maps.MapTypeId.ROADMAP) {
          setMarkerLabelStyle({
            ...markerLabelStyle,
            color: '#000000',
            className: classes.markerLabel,
            fontWeight: 'bold'
          })
        } else {
          setMarkerLabelStyle({
            ...markerLabelStyle,
            color: '#FFFFFF',
            className: classes.markerLabelSatellite,
            fontWeight: 'normal'
          })
        }
      }}
    >
      {props.markers.map((x) => (
        <>
          {x.name && x.id && x.latitude && x.longitude && (
            <Marker
              key={x.id}
              position={{ lat: x.latitude, lng: x.longitude }}
              icon={{
                url: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2.png',
                labelOrigin: new google.maps.Point(10, 48),
              }}
              label={{ text: x.name, ...markerLabelStyle }}
              onClick={() => setActiveMarker(x.id)}
            >
              {activeMarker == x.id && (
                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                  <Grid container direction='column' className={classes.modalWrapper}>
                    {getMapModalTitle(x.name)}
                    {x.addressLine1 &&
                      getMapModalRow(props.modalLabels.addressLabel, x.addressLine1)}
                    {x.distance &&
                      x.units &&
                      getMapModalRow(
                        props.modalLabels.distanceLabel,
                        getDistance(x.distance, x.units),
                      )}
                    {x.phone &&
                      getMapModalRow(props.modalLabels.phoneLabel, x.phone)}
                    {x.email &&
                      getMapModalRow(props.modalLabels.emailLabel, x.email)}
                    {x.approvals && x.approvals.length > 0 &&
                      getMapModalMultiValueRow(props.modalLabels.approvalsLabel, x.approvals)}
                    <Grid item container className={classes.modalButtonContainer} direction='column'>
                      <Grid item>
                        <ClaimButton
                          label={props.modalLabels.selectButton}
                          externalClassName={classes.modalButtonStyle}
                          handleClick={() => props.handleMarkerSubmit(x, info)}
                        />
                      </Grid>
                      {props.modalLabels.closeMarkerInfoButton && <Grid item>
                        <ClaimButton
                          label={props.modalLabels.closeMarkerInfoButton}
                          externalClassName={classes.modalButtonStyle}
                          handleClick={() => setActiveMarker(null)}
                        />
                      </Grid>}
                    </Grid>
                  </Grid>
                </InfoWindow>
              )}
            </Marker>
          )}
        </>
      ))}
    </GoogleMap>
  );
});

export default GoogleMapsWithMarkers;

GoogleMapsWithMarkers.propTypes = {
  props: PropTypes.shape({
    markers: PropTypes.arrayOf(
      PropTypes.shape({
        assigneeEntityId: PropTypes.number,
        entity: PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          entityType: PropTypes.string,
          typeId: PropTypes.number,
          address: PropTypes.shape({
            addressLine1: PropTypes.string,
            city: PropTypes.string,
            administrativeArea: PropTypes.string,
            country: PropTypes.string,
            postalCode: PropTypes.string,
          }),
          contactInfo: PropTypes.shape({
            primaryPhone: PropTypes.string,
            emails: PropTypes.arrayOf(PropTypes.string),
          }),
        }),
        location: PropTypes.shape({
          latitude: PropTypes.number,
          longitude: PropTypes.number,
        }),
        distanceStats: PropTypes.shape({
          distance: PropTypes.number,
          units: PropTypes.string,
        }),
        properties: PropTypes.shape({
          paintlessDentRepair: PropTypes.string,
          isTestParticipant: PropTypes.string,
          p457184Status: PropTypes.string,
          participantProfileStatus: PropTypes.string,
          oemApproval: PropTypes.string,
        }),
      }),
    ),
    modalLabels: PropTypes.shape({
      header: PropTypes.string,
      loadingHeader: PropTypes.string,
      noMarkers: PropTypes.string,
      selectButton: PropTypes.string,
      cancelButton: PropTypes.string,
      distanceLabel: PropTypes.string,
      addressLabel: PropTypes.string,
      phoneLabel: PropTypes.string,
      emailLabel: PropTypes.string,
    }),
    handleMarkerSubmit: PropTypes.func,
  }),
};
