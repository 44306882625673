// src/auth.js
import { JSEncrypt } from 'jsencrypt';

export function encryptMessage (message, publicKey) {
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);
  return encrypt.encrypt(message);
}


