import { useCallback } from 'react';

function useFocusOnTab(parentId, elementToFocusTag) {
  return useCallback(() => {
    const parent = document.getElementById(parentId);
    if (!parent) return;

    let elements = parent.getElementsByTagName(elementToFocusTag);
    if (!elements || elements.length === 0) return;

    let elementToFocus = elements[0];
    if (!elementToFocus) return;

    let hiddenFocus = document.createElement('span');
    hiddenFocus.setAttribute('tabindex', '0');
    hiddenFocus.setAttribute('style', 'display: hidden');

    elementToFocus.before(hiddenFocus);

    hiddenFocus.focus({ preventScroll: true });
    hiddenFocus.addEventListener('focusout', (e) => e.target.removeAttribute('tabindex'));
  }, [parentId, elementToFocusTag]);
}

export default useFocusOnTab;
