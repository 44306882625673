import { hooks } from 'botframework-webchat-component';
import PropTypes from 'prop-types';
import React from 'react';
import ClaimButton from '../../../components/ClaimButton';

const { useSendMessageBack } = hooks;

const RedirectToBooking = ({ cardAction: { displayText, value }, ...buttonProperties }) => {
  const sendMessageBack = useSendMessageBack();

  const openInNewTab = () => {
    window.open(value.RedirectURL, '_blank', 'noopener,noreferrer');
    sendMessageBack(displayText, value.Value, displayText);
  };

  return (
    <>
      <ClaimButton handleClick={() => openInNewTab()} label={displayText} {...buttonProperties} />
    </>
  );
};

export default RedirectToBooking;

RedirectToBooking.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};
