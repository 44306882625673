import React, { useEffect, useState } from 'react';
import ClaimButton from '../../../../components/ClaimButton';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import useHttpRequest from '../../../../hooks/useHttpRequest';

const confirmButtonId = 'confirmButton';
const searchAgainButtonId = 'searchAgainButton';
const itemNotFoundButtonId = 'itemNotFoundButton';
const buttonList = [confirmButtonId, searchAgainButtonId, itemNotFoundButtonId];

const useStyles = makeStyles((theme) => ({
  modalButton: {
    marginBottom: '8px',
  },
  root: {
    '& > div': {
      overflow: 'hidden !IMPORTANT',
    },
    position: 'relative',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  input: {
    paddingBottom: '10px',
    width: '100%',
    [theme.breakpoints.between(0, 350)]: {
      maxWidth: '240px',
    },
    [theme.breakpoints.between(350, 420)]: {
      maxWidth: '290px',
    },
  },
  sendBox: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '24px',
    maxWidth: '720px',
    padding: theme.spacing(0.875, 1, 0.875, 2),
    margin: '2px',
    border: 'solid 1px black',
    '&:focus-within': {
      outline: '1px solid black',
    },
  },
  sendBoxInput: {
    width: '100%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    background: 'transparent',
    minHeight: '20px',
    maxHeight: '120px',
    fontSize: '18px',
    letterSpacing: '-0.2px',
    padding: '0px',
  },
  menuItemRoot: {
    '&[aria-selected="true"]': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
    '&:hover': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
    '&:focus': {
      outline: '2px auto black',
      backgroundColor: '#D1D1D1',
    },
  },
  loading: {
    position: 'absolute',
    right: '5px',
    top: '4px',
  },
}));

const AutocompleteItemSearch = ({
  modalLabels,
  webApiToken,
  setIsAutocomplete,
  searchTimeout,
  sendMessageBackWithPdfCheck,
  webApiEndpoint,
}) => {
  const classes = useStyles();
  const [itemSearch, setItemSearch] = useState('');
  const [lastItemSearch, setLastItemSearch] = useState('');
  const [itemNotFound, setItemNotFound] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [suggestionList, setSuggestionList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const { sendRequest } = useHttpRequest();

  const onClickConfirmed = () => {
    sendMessageBackWithPdfCheck(
      { itemLookupConfirmed: true },
      JSON.stringify(itemSelected),
      itemSelected.productName,
    );
  };

  const onClickManualConfirmed = () => {
    sendMessageBackWithPdfCheck(
      { itemLookupConfirmed: false }, 
      JSON.stringify({ productDescription: itemSearch }),
      itemSearch);
  };

  const onClickSearchAgain = () => {
    setItemSearch('');
    setSuggestionList([]);
    setItemSelected(null);
  };

  const onClickNotListed = () => {
    setItemNotFound(true);
    setItemSearch(lastItemSearch);
  };

  const onClickBack = () => {
    setItemNotFound(false);
    setItemSearch('');
    setSuggestionList([]);
    setItemSelected(null);
  };

  const onClickHandler = (buttonId) => {
    switch (buttonId) {
      case confirmButtonId: {
        return onClickConfirmed();
      }
      case searchAgainButtonId: {
        return onClickSearchAgain();
      }
      case itemNotFoundButtonId: {
        setIsAutocomplete(true);
        return onClickNotListed();
      }
    }
  };

  const disableHandler = (buttonId) => {
    switch (buttonId) {
      case confirmButtonId: {
        return !itemSelected;
      }
      case searchAgainButtonId: {
        return !itemSelected;
      }
      case itemNotFoundButtonId: {
        return false;
      }
    }
  };

  const onItemSearched = (value) => {
    setItemSearch(value);
    
    if (value) {
      setLastItemSearch(value);
    }

    if (value.length >= 3) {
      if (itemSelected) {
        setItemSelected(null);
      }
      setShowLoading(true);
    } else {
      setSuggestionList([]);
      setShowLoading(false);
    }
  };

  const onItemSelected = (value) => {
    setItemSelected(value);
    if (value !== null) {
      setShowLoading(false);
    }
  };
  const applyData = (data) => {
    setShowLoading(false);

    if (data.products && data.products.length > 0) {
      setSuggestionList(data.products);
    }
  };
  useEffect(() => {
    if (itemSearch.length >= 3 && !itemSelected && !itemNotFound) {
      const getData = setTimeout(async () => {
        sendRequest(
          {
            url: `${webApiEndpoint}${itemSearch}`,
            headers: {
              Authorization: 'Bearer ' + webApiToken,
            },
          },
          applyData,
        );
      }, searchTimeout);
      return () => clearTimeout(getData);
    }
  }, [itemSearch, itemSelected, webApiToken, searchTimeout, itemNotFound, sendRequest, webApiEndpoint]);

  return (
    <div className={classes.container}>
      {!itemNotFound ? (
        <>
          <div className={classes.input}>
            <div className={classes.root}>
              <Autocomplete
                classes={{ option: classes.menuItemRoot }}
                id='be-valued-search'
                options={suggestionList}
                filterOptions={(x) => x}
                getOptionLabel={(option) => {
                  return option.productName;
                }}
                renderInput={(params) => (
                  <div className={classes.sendBox + ' modal-input-answer'} ref={params.InputProps.ref}>
                    <input
                      {...params.inputProps}
                      autoFocus
                      tabIndex='0'
                      placeholder={modalLabels.placeHolder}
                      className={classes.sendBoxInput}
                      value={itemSearch}
                    />
                  </div>
                )}
                inputValue={itemSearch}
                onInputChange={(e, value) => onItemSearched(value)}
                onChange={(event, newValue) => onItemSelected(newValue)}
              />
              {showLoading && (
                <CircularProgress className={classes.loading} style={{ width: '30px', height: '30px' }} />
              )}
            </div>
          </div>
          {buttonList.map((x) => (
            <div key={x} className={classes.modalButton}>
              <ClaimButton
                handleClick={() => onClickHandler(x)}
                label={modalLabels[x]}
                disabled={!!disableHandler(x)}
                externalClassName='external-modal-button'
              />
            </div>
          ))}
        </>
      ) : (
        <>
          <div className={classes.input}>
            <div className={classes.root}>
              <div className={classes.sendBox + ' modal-input-answer'}>
                <input
                  autoFocus
                  tabIndex='0'
                  placeholder={modalLabels.placeHolder}
                  className={classes.sendBoxInput}
                  onChange={(event) => setItemSearch(event.target.value)}
                  value={itemSearch}
                />
              </div>
            </div>
          </div>
          <div className={classes.modalButton}>
            <ClaimButton
              handleClick={() => onClickManualConfirmed()}
              label={modalLabels.confirmButton}
              disabled={!itemSearch}
              externalClassName='external-modal-button'
            />
          </div>
          <div className={classes.modalButton}>
            <ClaimButton
              label={modalLabels.backButton}
              handleClick={() => {
                setIsAutocomplete(false);
                onClickBack();
              }}
              externalClassName='external-modal-button'
            />
          </div>
        </>
      )}
    </div>
  );
};

AutocompleteItemSearch.propTypes = {
  onLocationSelected: PropTypes.func,
  modalLabels: PropTypes.shape({
    placeHolder: PropTypes.string.isRequired,
    confirmButton: PropTypes.string.isRequired,
    backButton: PropTypes.string.isRequired,
    searchAgainButton: PropTypes.string.isRequired,
    itemNotFoundButton: PropTypes.string.isRequired,
    itemDetail: PropTypes.string.isRequired,
  }),
  webApiToken: PropTypes.string.isRequired,
  setIsAutocomplete: PropTypes.func,
  searchTimeout: PropTypes.number,
  sendMessageBackWithPdfCheck: PropTypes.func,
  webApiEndpoint: PropTypes.string,
};

export default AutocompleteItemSearch;
