import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ videoUrl, autoPlay, showControls, subtitlesUrl, showSubtitles }) => {

  const [hasVideoPlayerError, setVideoPlayerHasError] = useState(false);
  const [isReady, setIsReady] = useState(false);

  return (
    <div style={{display: !isReady  ? 'none' : ''}}>
        <ReactPlayer
          url={videoUrl}
          playing={autoPlay}
          muted={autoPlay} //videos must be muted in order to play automatically
          controls={showControls}
          config={{
            file: {
              attributes: {
                crossOrigin: 'anonymous',
              },
              tracks: [
                {
                  kind: 'subtitles',
                  src: subtitlesUrl,
                  srcLang: 'en',
                  default: showSubtitles,
                },
              ],
            },
          }}

          height="100%"
          width="calc(100% + 17px)"
          style={{ maxWidth: '698px' }}
          onReady={()=>{
            if(!hasVideoPlayerError){
              setIsReady(true)
            }
           }
          }
          onError={()=> {
            setVideoPlayerHasError(true);
          }}
        />
    </div>
  );
};

VideoPlayer.propTypes = {
  clientConfigs: PropTypes.shape({
    ClientAssetsUrl: PropTypes.string,
  }),
  videoUrl: PropTypes.string,
  autoPlay: PropTypes.bool,
  showControls: PropTypes.bool,
  subtitlesUrl: PropTypes.string,
  showSubtitles: PropTypes.bool,
};

export default VideoPlayer;
