import React from 'react';
import { createTheme, CssBaseline } from '@material-ui/core';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import PropTypes from 'prop-types';
import { colorPalette } from 'ri-components';

const { white } = colorPalette;

export const MD_LAYOUT_BREAKPOINT = 720;
export const XXL_LAYOUT_BREAKPOINT = 1800;

export const globalTypography = {
  h5: {
    fontSize: '23px',
    letterSpacing: '0.42px',
    fontWeight: 'bold',
  },
  subtitle1: {
    fontSize: '19px', // Body Lg Medium
    letterSpacing: '0.15px',
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: '17px', // Body Md
    letterSpacing: '0.1px',
    fontWeight: 400,
  },
};

const globalTheme = createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          '-webkit-text-size-adjust': '100%',
        },
        body: {
          fontWeight: 400,
          backgroundColor: white,
          '-webkit-tap-highlight-color': 'transparent',
        },
        textArea: {
          fontFamily: 'inherit',
        },
        ul: {
          margin: 0,
          padding: 0,
        },
        input: {
          fontFamily: 'inherit',
        },
        button: {
          font: 'inherit',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        },
      },
    },
    MuiTypography: {
      root: {
        wordBreak: 'break-word',
      },
    },
  },
  typography: { ...globalTypography },
});

function GlobalThemeProvider({ children }) {
  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

GlobalThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default GlobalThemeProvider;
