import React, { useRef, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { colorPalette } from 'ri-components';
import screenfull from 'screenfull';
import DeleteAttachmentButton from '../delete-attachment-button/DeleteAttachmentButton';
import { CircularProgress } from '@material-ui/core';
import { isJsonResponse } from '../../../utils/isJsonResponse';

const { white } = colorPalette;

const useStyles = makeStyles((theme) => ({
  videoContainer: {
    width: '80px',
    overflow: 'hidden',
    height: '80px',
    position: 'relative',
    justifyContent: 'center',
    margin: theme.spacing(0, 0.5, 1, 0.5),
    alignItems: 'center',
    borderRadius: '2px',
  },
  icon: {
    zIndex: '100',
    width: '36px',
    height: '36px',
    margin: '22px',
    color: white,
    cursor: 'pointer',
    opacity: 0.65,
  },
  video: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: '0',
    left: '0',
    display: 'block',
    objectFit: ({ isFullscreen }) => (isFullscreen ? 'contain' : 'cover'),
  },
  image: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '2px',
    filter: (props) => (props.isActivityMasked ? 'blur(0.8rem)' : 'none'),
    background: (props) => (props.isActivityMasked ? 'grey' : 'none')
  },
  circularProgress: {
    color: theme.message.user.backgroundColor,
    marginTop: '20px',
    marginLeft: '20px',
  },
}));

async function fetchData(fileId, fileExtension, webApiToken) {
  const urlResponse = await fetch('api/app-config');
  const urlResult = await urlResponse.json();
  const result = await fetch(`${urlResult.webApiUrl}/api/files/get-file/${fileId}/${fileExtension}`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + webApiToken,
    },
  });
  let data;
  if (result.ok) {
    if (isJsonResponse(result)) {
      data = await result.json();
    } else {
      data = await result.text();
    }
    return data;
  }
}

const VideoChatDisplay = ({ fileId, name, activityId, fileExtension, hideDeleteButton, webApiToken, isActivityMasked }) => {
  const [isVideoFullscreen, setIsVideoFullscreen] = useState(false);
  const classes = useStyles({ isFullscreen: isVideoFullscreen, isActivityMasked });
  const videoRef = useRef(null);
  const [videoIsPlaying, setVideoPlaying] = useState(false);
  const [videoCanPlay, setVideoCanPlay] = useState(false);
  const [streamData, setStreamData] = useState(null);

  useEffect(() => {
    let mounted = true;
    if (!isActivityMasked) {
      fetchData(fileId, fileExtension, webApiToken).then((data) => {
        if (mounted) {
          setStreamData(data);
        }
      });
    }
    return () => (mounted = false);
  }, [fileId, fileExtension, webApiToken, isActivityMasked]);

  useEffect(() => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  }, [streamData]);

  const playVideo = () => {
    videoRef.current.play();
    if (screenfull.isEnabled) {
      screenfull.request(videoRef.current);
      screenfull.onchange(() => {
        if (!screenfull.isFullscreen) {
          videoRef.current?.pause();
          setIsVideoFullscreen(false);
        } else {
          setIsVideoFullscreen(true);
        }
      });
    }
  };

  const stopVideo = () => {
    videoRef.current.pause();
  };

  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      stopVideo();
    }
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  };

  document.onfullscreenchange = () => {
    if (document.fullscreenElement === null) {
      stopVideo();
    }
  };

  if (isActivityMasked) {
    return <div className={classes.image}></div>
  } else {
    const displayVideo = streamData ? (
      <div className={classes.videoContainer}>
        <video
          data-testid='video'
          onPlaying={() => setVideoPlaying(true)}
          onEnded={() => {
            setVideoPlaying(false);
            exitFullScreen();
          }}
          onCanPlay={() => setVideoCanPlay(true)}
          onPause={() => setVideoPlaying(false)}
          className={classes.video}
          ref={videoRef}
        >
          <source src={streamData} type='video/mp4' />
          <source src='movie.ogg' type='video/ogg' />
        </video>
        <div
          style={{
            position: 'absolute',
            display: !!videoRef && !!videoRef.current && videoIsPlaying ? 'none' : null,
          }}
        >
          <img
            src='/icons/Play.png'
            style={{ opacity: videoCanPlay ? '1' : '0.65' }}
            className={classes.icon}
            onClick={() => playVideo()}
            alt='play-button'
          />
        </div>
        {!hideDeleteButton && <DeleteAttachmentButton name={name} activityId={activityId} fileId={fileId} />}
      </div>
    ) : (
      <div className={classes.image}>
        <CircularProgress className={classes.circularProgress} />{' '}
      </div>
    );
    return displayVideo;
  }
};

VideoChatDisplay.propTypes = {
  fileId: PropTypes.string,
  name: PropTypes.string,
  activityId: PropTypes.string,
  hideDeleteButton: PropTypes.bool,
  fileExtension: PropTypes.string,
  webApiToken: PropTypes.string,
  isActivityMasked: PropTypes.bool,
  isContinue: PropTypes.bool,
};

export default VideoChatDisplay;
