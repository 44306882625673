import React, { useState, useCallback, useMemo } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import PropTypes from 'prop-types';
import ClaimButton from '../../../components/ClaimButton';
import AutocompleteMaps from './google-maps/AutocompleteMaps';
import { hooks } from 'botframework-webchat-component';
import { EventList } from '../utils/EventList';
import LoadScriptWrapper from '../../../components/LoadScriptWrapper';
import { makeStyles } from '@material-ui/core/styles';
import useLastActivity from '../hooks/useLastActivity';

const { useSendEvent } = hooks;

const useStyles = makeStyles(() => ({
  mapModalOverlay: {
    padding: '24px',
  },
}));

const GoogleMapsButton = ({
  cardAction: { displayText, value },
  sendMessageBackWithPdfCheck,
  ...buttonProperties
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const mapLabels = JSON.parse(value);
  const classes = useStyles();
  const sendEvent = useSendEvent();
  const lastActivity = useLastActivity();

  const googleMapsUrl = useMemo(
    () => `${window.location.protocol}//${window.location.host}/api/app-config/maps-script`,
    [],
  );

  const onLocationSelectedHandler = useCallback(
    (address) => {
      const size = '400x400';
      const mapsStaticApiUrl = `${window.location.protocol}//${window.location.host}/api/app-config/static-map/`;
      const locationImage = `${mapsStaticApiUrl}?center=${address.address}&markers=color:red|${address.address}&zoom=13&size=${size}`;
      sendMessageBackWithPdfCheck(address, address.address, address.address);
      if (address.foundByGoogle) {
        setTimeout(() => {
          sendEvent(EventList.ProvideMapSnapshot, {
            contentUrl: locationImage,
            name: 'googleResult',
            contentType: 'image/jpg',
            editableKey: lastActivity.editablekey,
          });
        }, 150);
      }
    },
    [sendEvent, sendMessageBackWithPdfCheck, lastActivity],
  );

  return (
    <>
      <ClaimButton handleClick={() => setIsOpen(true)} label={displayText} {...buttonProperties} />
      <div style={{ display: !isOpen ? 'none' : '' }}>
        <ModalOverlay className={classes.mapModalOverlay} open={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlayTitle style={{ marginBottom: '-28px' }}>{mapLabels.header}</ModalOverlayTitle>
          <ModalOverlayBody>
            <LoadScriptWrapper url={googleMapsUrl}>
              <AutocompleteMaps onLocationSelected={onLocationSelectedHandler} mapLabels={mapLabels} />
            </LoadScriptWrapper>
          </ModalOverlayBody>
        </ModalOverlay>
      </div>
    </>
  );
};

export default GoogleMapsButton;

GoogleMapsButton.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  sendMessageBackWithPdfCheck: PropTypes.func,
};
