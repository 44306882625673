import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

export const ClaimStatusContext = React.createContext({
    status: {},
    setContextValue: () => { },
});

export const ClaimStatusProvider = ({ children }) => {
    const [value, setValue] = useState('New');
    const [index, setIndex] = useState(-1);
    const setContextValue = (claimStatus) => {
        setValue(claimStatus.value);
        setIndex(claimStatus.index);
    };
    const claimStatusContext = {
        status: {
            value: value,
            index: index
        },
        setContextValue: setContextValue,
    };
    return <ClaimStatusContext.Provider value={claimStatusContext}>{children}</ClaimStatusContext.Provider>;
};
ClaimStatusProvider.propTypes = {
    children: PropTypes.any,
};