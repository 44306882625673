const hasSqlInjection = (id) => {
  const sqlInjectionPattern = /('|--|;|\/\*|\*\/|xp_)/i;
  return sqlInjectionPattern.test(id);
};

const validateAndParseGuid = (id) => {
  try {
    if (hasSqlInjection(id)) {
      return null;
    }

    var guidRegexValidater = new RegExp('^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$', 'i');
    if (guidRegexValidater.test(id) === true) {
      return id;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

export default validateAndParseGuid;