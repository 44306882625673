import { hooks } from 'botframework-webchat-component';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ClaimButton from '../../../components/ClaimButton';
import { resetSelectedFiles } from './UploadFileButton';

const { useSendMessageBack } = hooks;

const SubmitClaimMessageBack = ({
  cardAction: { displayText, text, title, value },
  buttonProperties,
  inputValue,
}) => {
  const sendMessageBack = useSendMessageBack();

  const handleClick = useCallback(() => {
    let submittedText = text;
    sendMessageBack({ value, inputValue }, submittedText, displayText, inputValue);
  }, [displayText, sendMessageBack, text, value, inputValue]);

  // Web Chat does the heavylifting for us by exposing a "sendMessageBack" function.
  // We do need to handle "displayText" manually in our renderer though.
  return (
    <ClaimButton
      handleClick={() => {
        resetSelectedFiles();
        handleClick();
      }}
      label={title}
      {...buttonProperties}
    />
  );
};

export default SubmitClaimMessageBack;

SubmitClaimMessageBack.defaultProps = {
  cardAction: undefined,
  buttonProperties: undefined,
};

SubmitClaimMessageBack.propTypes = {
  cardAction: PropTypes.object,
  inputValue: PropTypes.string,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};
