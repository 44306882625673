import React, { useEffect, useState } from 'react';
import { hooks } from 'botframework-webchat-component';
import { makeStyles } from '@material-ui/core/styles';
import MessageBubble from '../message-bubble/MessageBubble';
import BotAvatar from '../avatar/BotAvatar';

const { useActiveTyping } = hooks;

const useStyles = makeStyles((theme) => ({
  '@keyframes skBouncedelay': {
    '0%, 80%, 100%': {
      transform: 'scale(0)',
    },
    '40%': {
      transform: 'scale(1)',
    },
  },
  typingWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
  },
  spinner: {
    margin: '0 -8px',
    width: '52px',
    textAlign: 'center',
    '& > div': {
      width: '10px',
      height: '10px',
      margin: '0 1px',
      backgroundColor: '#999b9f',
      borderRadius: '100%',
      display: 'inline-block',
      animation: '$skBouncedelay 1.2s infinite ease-in-out both',
    },
    '& .bounce1': {
      animationDelay: '-0.32s',
      backgroundColor: theme.message.bot.typingPointsBackgroundColor,
    },
    '& .bounce2': {
      animationDelay: '-0.16s',
      backgroundColor: theme.message.bot.typingPointsBackgroundColor,
    },
    '& .bounce3': {
      backgroundColor: theme.message.bot.typingPointsBackgroundColor,
    },
  },
}));

const useTypingIndicatorVisible = () => {
  const [activeTyping] = useActiveTyping();
  return [!!Object.values(activeTyping).filter(({ role }) => role !== 'user').length];
};

const TypingIndicator = () => {
  const classes = useStyles();
  const [visible] = useTypingIndicatorVisible();
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    let mounted = true;
    setTimeout(() => {
      if (mounted) {
        setTyping(true);
      }
    }, 400);
    return () => {
      mounted = false;
      setTyping(false);
    };
  }, []);

  return (
    visible &&
    typing && (
      <div className={classes.typingWrapper} data-testid='typing-indicator'>
        <BotAvatar />
        <MessageBubble from='bot' isText={false}>
          <div className={classes.spinner}>
            <div className='bounce1' />
            <div className='bounce2' />
            <div className='bounce3' />
          </div>
        </MessageBubble>
      </div>
    )
  );
};

export default TypingIndicator;
