import moment from 'moment';

export const DateFormats = Object.freeze({
  displayFormat: 'DD MMM YYYY',
  utcDateFormat: 'yyyy-MM-DDT00:00:00.0000000[Z]',
});

export const calculateRelativeDate = (yourDate) => {
  if (!yourDate) {
    return null;
  }
  let relativeDate;
  const duration = moment.duration(moment().diff(moment(yourDate)));
  let daysAgo = duration.asDays();
  if (daysAgo >= 0) {
    daysAgo = Math.floor(daysAgo);
    if (daysAgo === 0) {
      relativeDate = 'Today';
    } else if (daysAgo === 1) {
      relativeDate = 'Yesterday';
    } else {
      relativeDate = `${daysAgo} days ago`;
    }
  }
  return relativeDate;
};

export const isDate = (string) => isNaN(string) && !isNaN(Date.parse(string));
