import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { colorPalette } from 'ri-components';
import DeleteAttachmentButton from '../delete-attachment-button/DeleteAttachmentButton';
import { ReactComponent as PdfIcon } from './icons/file-pdf-solid.svg';
import { defaultWebChatTheme } from '../../../themes/default-webchat-theme';

const { white, black } = colorPalette;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80px',
    height: '80px',
    margin: theme.spacing(0, 0.5, 1, 0.5),
    padding: theme.spacing(0.375, 0.375),
    display: 'flex',
    flexFlow: 'row wrap',
    backgroundColor: (props) => (props.isActivityMasked ? 'none' : `${white} !important`),
    alignContent: 'center',
    alignItems: 'flex-start',
    borderRadius: '2px',
    border: 'solid 1px #cccdd1',
    letterSpacing: '-1.5px',
    position: 'relative',
    filter: (props) => (props.isActivityMasked ? 'blur(0.8rem)' : 'none'),
    background: (props) => (props.isActivityMasked ? 'grey' : 'none')
  },
  pdfFileNameContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  pdfFileName: {
    color: black,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingTop: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    fontSize: '15px',
    letterSpacing: '0px',
  },
  pdfIcon: {
    width: '36px',
    height: '36px',
  },
  pdfIconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(0.75),
  },
}));

const PdfChatDisplay = ({ name, fileId, displayName, activityId, isActivityMasked }) => {
  const classes = useStyles({ isActivityMasked });

  if (isActivityMasked) {
    return <div className={classes.root}></div>
  } else {
    return (
      <div className={classes.root}>
        <div className={classes.pdfIconContainer}>
          <PdfIcon className={classes.pdfIcon} fill={defaultWebChatTheme.palette.primary.main} />
        </div>
        <div className={classes.pdfFileNameContainer}>
          <span className={classes.pdfFileName}>{displayName}</span>
        </div>
        <DeleteAttachmentButton name={name} activityId={activityId} fileId={fileId} />
      </div>
    );
  }
}

export default PdfChatDisplay;

PdfChatDisplay.propTypes = {
  name: PropTypes.string,
  fileId: PropTypes.string,
  displayName: PropTypes.string,
  activityId: PropTypes.string,
  isContinue: PropTypes.bool,
  isActivityMasked: PropTypes.bool
};
