import React, { useEffect } from 'react';
import Arrow from './Arrow';
import Attachment from '../Attachment';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import useFocusOnTab from '../hooks/useFocusOnTab';
import { colorPalette } from 'ri-components';

const { hyperlinkBlue } = colorPalette;

const useStyles = makeStyles((theme) => ({
  carousel: {
    margin: theme.spacing(1, 2, 1.5),
    '&:focus': {
      outline: 'none',
    },
  },
  scrollMenuArrow: {
    cursor: 'pointer',
    padding: '16px 0',
  },
  scrollMenuArrowDisabled: {
    visibility: 'hidden',
  },
  menuItemWrapper: {
    '& button:focus': {
      boxShadow: `0 0 3pt 2pt ${hyperlinkBlue}`,
    },
  },
}));

const Carousel = ({ activity }) => {
  const classes = useStyles();
  const data = activity.attachments.map((attachment, index) => (
    <Attachment key={index} role={activity.from.role} {...attachment} />
  ));

  const focusOnCarouselWrapper = useFocusOnTab('carousel-wrapper', 'button');

  useEffect(() => {
    const items = document.getElementsByClassName(classes.menuItemWrapper);
    for (let i = 0; i < items.length; i++) {
      items[i].removeAttribute('tabindex');
    }

    setTimeout(() => {
      focusOnCarouselWrapper();
    }, 100);
  }, [classes.menuItemWrapper, focusOnCarouselWrapper]);

  return (
    <div className={classes.carousel} data-testid='carousel-scroll-menu' id='carousel-wrapper'>
      <ScrollMenu
        hideArrows
        scrollToSelected
        hideSingleArrow
        inertiaScrolling
        wheel={false}
        data={data}
        scrollBy={1}
        arrowLeft={<Arrow direction='left' />}
        arrowRight={<Arrow direction='right' />}
        itemClass={classes.menuItemWrapper}
        arrowClass={classes.scrollMenuArrow}
        arrowDisabledClass={classes.scrollMenuArrowDisabled}
      />
    </div>
  );
};

export default Carousel;

Carousel.propTypes = {
  activity: PropTypes.object,
};
