import { makeStyles } from "@material-ui/core";
import { colorPalette } from 'ri-components';

const { grey } = colorPalette;

export default makeStyles((theme) => ({
    selectMenuList: {
      maxHeight: '320px',
    },
    paper: {
      top: '53.5vh !important',
    },
    modalTitle: {
      marginBottom: theme.spacing(2),
    },
    modalText: {
      marginBottom: theme.spacing(3.25),
    },
    datePickerContainer: {
      display: 'flex',
      margin: theme.spacing(1, 0, 5),
      '& > *:not(:last-child)': {
        marginRight: '8px',
      },
    },
    selectDateButton: {
      margin: '0 auto !IMPORTANT',
    },
    selectedMenuItem: {
      overflow: 'unset',
      backgroundColor: '#D1D1D1',
      outline: '2px auto black',
      '&:hover': {
        outline: '2px auto black',
        backgroundColor: '#D1D1D1',
      },
    },
    menuItemRoot: {
      overflow: 'unset',
      '&[aria-selected="true"]': {
        backgroundColor: '#D1D1D1',
      },
      '&:hover': {
        outline: '2px auto black',
        backgroundColor: '#D1D1D1',
      },
      '&:focus': {
        outline: '2px auto black',
        backgroundColor: '#D1D1D1',
      },
    },
    selectError: {
      outline: 'auto #ff0000a3',
      transition: '0.5s outline',
      borderRadius: '20px',
    },
    select: {
      outline: 'none ' + grey[2],
    },
  }));
