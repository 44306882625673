import React, { useMemo } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { useApiConfigs } from './ApiConfigsContextProvider';
import { isDev } from '../utils/isDev';

export const reactPlugin = new ReactPlugin();

export function withTracking(Component) {
  if (!reactPlugin || !reactPlugin.core) {
    return Component;
  }
  return withAITracking(reactPlugin, Component);
}

function AppInshightsProvider({ children }) {
  const configs = useApiConfigs();

  useMemo(() => {
    if (!isDev()) {
      const browserHistory = createBrowserHistory({ basename: '' });
      const appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: configs.appInsights_InstrumentalKey,
          disableCookiesUsage: true,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
          },
        },
      });
      appInsights.loadAppInsights();
    }
  }, [configs.appInsights_InstrumentalKey]);

  if (isDev()) return children;

  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
}

AppInshightsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppInshightsProvider;
