import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CardActionButton from '../suggested-actions/CardActionButton';
import CardMedia from '@material-ui/core/CardMedia';
import { colorPalette } from 'ri-components';
import { hooks } from 'botframework-webchat-component';
import useLastActivity from '../hooks/useLastActivity';

const { grey } = colorPalette;
const { usePostActivity } = hooks;

const useStyles = makeStyles((theme) => ({
  card: {
    height: 196,
    width: 180,
    borderRadius: 8,
    margin: theme.spacing(0, 0.5),
    padding: theme.spacing(2, 1),
    border: 'solid 1px',
    borderColor: grey[4],
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  media: {
    margin: '0 auto',
    backgroundColor: grey[4],
    height: 60,
    width: 60,
  },
  cardTitle: {
    margin: theme.spacing(1, 0, 1.5),
    fontSize: '17px',
    fontWeight: 'bold',
    lineHeight: '24px',
    height: '48px',
    letterSpacing: '0.12px',
    color: grey[10],
    overflow: 'hidden',
    whiteSpace: 'pre-wrap',
    display: '-webkit-box',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  cardActionButton: {
    width: '77px',
  },
}));

const HeroCard = ({ content }) => {
  const classes = useStyles();
  const lastActivity = useLastActivity();
  const postActivity = usePostActivity();

  const preventDragHandler = (event) => {
    event.preventDefault();
  };

  const sendMessageBackWithPdfCheck = (value, text, displayText, name = null) => {
    const activity = {
      name,
      value,
      text,
      channelData: { messageBack: { displayText } },
      type: 'message',
    };

    if (lastActivity?.pdflabel) {
      postActivity({
        ...activity,
        pdfLabel: lastActivity.pdflabel,
        pdfValue: displayText,
      });
    } else {
      postActivity(activity);
    }
  };

  return (
    <div className={classes.card}>
      {!!content && !!content.images && (
        <CardMedia
          component='div'
          draggable={false}
          onDragStart={preventDragHandler}
          className={classes.media}
          image={content.images[0].url}
        />
      )}
      {!!content && !!content.title && <div className={classes.cardTitle}>{content.title}</div>}
      <div style={{ flexGrow: 1 }} />
      {!!content &&
        !!content.buttons &&
        content.buttons.map((button, index) => (
          <CardActionButton
            key={index}
            className={classes.cardActionButton}
            cardAction={button}
            buttonProperties={{ color: 'secondary', size: 'small' }}
            sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
          />
        ))}
    </div>
  );
};

export default HeroCard;

HeroCard.defaultProps = {
  content: undefined,
};

HeroCard.propTypes = {
  content: PropTypes.shape({
    images: PropTypes.array,
    buttons: PropTypes.array,
    title: PropTypes.string,
  }),
};
