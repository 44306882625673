export const EditTypes = Object.freeze({
  Date: 'date',
  DateOfBirth: 'dateOfBirth',
  Time: 'time',
  Location: 'map',
  Text: 'text',
  ContactNumber: 'contactNumber'
});

export const EditTypesList = Object.values(EditTypes);
