import React, { useState, useCallback } from 'react';
import ClaimButton from '../../../../components/ClaimButton';
import { Select, ModalOverlay, Typo, colorPalette } from 'ri-components';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { DateFormats } from '../../../../utils/dateUtils';
import { localeMap } from '../../utils/LocaleMap';
import useStyles from "./RIDatePickerStyles";
import {
  AllDays,
  AllMonths,
  DefaultMinYearDiff,
  DefaultMaxYearDiff,
  getDaysInMonths,
  getDaysArray,
} from '../../utils/ridatepicker-utils';

const { white } = colorPalette;

const RIDatePickerButton = ({
  cardAction: { title, value },
  buttonProperties,
  sendMessageBackWithPdfCheck,
}) => {
  const [locale] = useState(!value.language || value.language === 'en' ? localeMap.en : value.language);
  moment.locale(locale);

  const classes = useStyles();

  const date = new Date();
  const minYearDiff = value.minyeardiff ?? DefaultMinYearDiff;
  const maxYearDiff = value.maxyeardiff ?? DefaultMaxYearDiff;
  const startYear = date.getFullYear() - minYearDiff;
  const endYear = date.getFullYear() - maxYearDiff;
  const allYears = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index).reverse();

  const [isOpen, setIsOpen] = useState(false);
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [invalidDay, setInvalidDay] = useState(false);

  const dateIsPicked = day !== '' && month !== '' && year !== '';
  const selectedDate = `${day} ${month} ${year}`;

  const days = month ? getDaysArray(getDaysInMonths(year)[month]) : AllDays;

  const changeDay = (event) => {
    const day = event.target.value;
    setDay(day);
    setInvalidDay(false);
  };

  const changeMonth = (event) => {
    const month = event.target.value;
    setMonth(month);

    if (day > getDaysInMonths(year)[month]) {
      setDay('');
      setInvalidDay(true);
    }
  };

  const changeYear = (event) => {
    const year = event.target.value;
    setYear(year);

    if (day > getDaysInMonths(year)[month]) {
      setDay('');
      setInvalidDay(true);
    }
  };

  const handleClick = useCallback(() => {
    let momentDate = moment(selectedDate);
    let formattedDate = momentDate.format(DateFormats.displayFormat);
    sendMessageBackWithPdfCheck(formattedDate, formattedDate, selectedDate, null, value);
  }, [sendMessageBackWithPdfCheck, selectedDate, value]);

  return (
    <>
      <ClaimButton handleClick={() => setIsOpen(true)} label={title} {...buttonProperties} />
      <ModalOverlay open={isOpen} onClose={() => setIsOpen(false)}>
        <Typo variant='bodyLg' fontWeight='medium' className={classes.modalTitle}>
          {value.header}
        </Typo>
        <Typo variant='bodyMd' fontWeight='default' className={classes.modalText}>
          {value.bodycontent}
        </Typo>
        <div className={classes.datePickerContainer}>
          <Select
            className={invalidDay ? classes.selectError : classes.select + ' modal-dropdown-sm'}
            label={value.daylabeltext ? value.daylabeltext : 'Day'}
            onChange={changeDay}
            value={day}
            backgroundColor={white}
            MenuProps={{
              classes: {
                list: classes.selectMenuList,
                paper: classes.paper,
              },
            }}
          >
            {days.map((day) => (
              <MenuItem
                key={day}
                value={day}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.selectedMenuItem,
                }}
              >
                {day}
              </MenuItem>
            ))}
          </Select>
          <Select
            label={value.monthlabeltext ? value.monthlabeltext : 'Month'}
            onChange={changeMonth}
            className='modal-dropdown-sm'
            value={month}
            backgroundColor={white}
            MenuProps={{
              classes: {
                list: classes.selectMenuList,
                paper: classes.paper,
              },
            }}
          >
            {AllMonths.map((month) => (
              <MenuItem
                key={month}
                value={month}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.selectedMenuItem,
                }}
              >
                {month}
              </MenuItem>
            ))}
          </Select>
          <Select
            label={value.yearlabeltext ? value.yearlabeltext : 'Year'}
            onChange={changeYear}
            value={year}
            backgroundColor={white}
            className='modal-dropdown-sm'
            MenuProps={{
              classes: {
                list: classes.selectMenuList,
                paper: classes.paper,
              },
            }}
          >
            {allYears.map((year) => (
              <MenuItem
                key={year}
                value={year}
                classes={{
                  root: classes.menuItemRoot,
                  selected: classes.selectedMenuItem,
                }}
              >
                {year}
              </MenuItem>
            ))}
          </Select>
        </div>

        <ClaimButton
          fullWidth
          className={classes.selectDateButton}
          handleClick={handleClick}
          disabled={!dateIsPicked}
          label={value.submittext}
          externalClassName='external-modal-button'
        />
      </ModalOverlay>
    </>
  );
};

export default RIDatePickerButton;

RIDatePickerButton.defaultProps = {
  cardAction: undefined,
  buttonProperties: undefined,
};

RIDatePickerButton.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  sendMessageBackWithPdfCheck: PropTypes.func,
};
