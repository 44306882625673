import { useMemo } from 'react';
import { hooks } from 'botframework-webchat-component';
import { getLastActivityByType } from '../utils/activity-utils';

const { useActivities } = hooks;

function useLastActivity(activityType = 'message') {
  const [activities] = useActivities();

  const activity = useMemo(() => getLastActivityByType(activities, activityType), [activities, activityType]);

  return activity;
}

export default useLastActivity;
