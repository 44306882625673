import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import { Button } from '@material-ui/core';
import { useEffect } from 'react';
import { withClientConfigs } from '../../../services/ClientConfigsContextProvider';

const useStyles = makeStyles((theme) => ({
  mapModalOverlay: {
    padding: '14px',
    paddingBottom: '4px',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlayImage: {
    width: '100%',
    height: '100%',
  },
  cameraContainer: {
    position: 'relative',
    width: 'min(100%, 480px)',
    margin: 'auto',
    aspectRatio: '1 / 1',
  },
  cameraFeed: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  buttonSvg: {
    overflow: 'initial',
    width: '62px',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    marginBottom: '16px',
  },
}));

const CameraWithOverlay = ({
  clientConfigs: { ClientAssetsUrl },
  isOpen,
  setIsOpen,
  onChange,
  overlayName,
  title,
}) => {
  const classes = useStyles();
  const streamRef = useRef(null);
  const [hasStream, setHasStream] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const captureButtonHandleClick = useCallback(
    () => {
      const video = document.getElementById('camera-feed');
      const canvas = document.getElementById('canvas');
      const context = canvas.getContext('2d');
      // Ensure the canvas is the same size as the video
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // Draw the current frame from the video onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas image to a Blob
      canvas.toBlob(function (blob) {
        const file = new File([blob], 'upload.jpeg', { type: 'image/jpeg' })
        onChange({ files: [file] });
      }, 'image/jpeg');

      setIsOpen(false);
    },
    [onChange, setIsOpen],
  );

  useEffect(() => {
    setErrorMsg(null);
    // Request camera access
    if (isOpen) {
      navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment'
        }
      })
        .then(stream => {
          // Recieved camera access
          const video = document.getElementById('camera-feed');
          video.srcObject = stream;
          streamRef.current = stream;
          setHasStream(true);
          setErrorMsg(null);
        })
        .catch(err => {
          // Camera access error
          console.error('An error occurred: ' + err);
          setErrorMsg('Access to the camera was denied. Allow camera access and reopen the modal.');
        });
    } else if (streamRef.current) {
      // Stop camera access if it's still not stopped (when modal is closed)
      setHasStream(false);
      streamRef.current.getTracks().forEach(track => {
        track.stop();
      });
    }
  }, [isOpen, setErrorMsg]);

  return (
    <>
      <div style={{ display: !isOpen ? 'none' : '' }}>
        <ModalOverlay className={classes.mapModalOverlay} open={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlayTitle>{title}</ModalOverlayTitle>
          <ModalOverlayBody>
            <div id='camera-container' className={classes.cameraContainer}>
              <video id='camera-feed' autoPlay playsInline className={classes.cameraFeed} />
              {overlayName &&
                <div id='overlay' className={classes.overlay}>
                  <img src={`${ClientAssetsUrl}/${overlayName}`} className={classes.overlayImage} />
                </div>}
            </div>
            {errorMsg && <div id='error-message' className={classes.errorMessage}>{errorMsg}</div>}
            {!errorMsg && <Button id='capture-btn' onClick={captureButtonHandleClick} disabled={!hasStream}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className={classes.buttonSvg}>
                <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"></path>
              </svg>
            </Button>}
            <canvas id='canvas' width='640' height='480' style={{ display: 'none' }}></canvas>
          </ModalOverlayBody>
        </ModalOverlay>
      </div>
    </>
  );
};

export default withClientConfigs(CameraWithOverlay);

CameraWithOverlay.propTypes = {
  clientConfigs: PropTypes.object,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  onChange: PropTypes.func,
  overlayName: PropTypes.string,
  title: PropTypes.string,
};
