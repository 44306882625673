import { useState, useCallback } from 'react';
import { isJsonResponse } from '../utils/isJsonResponse';

const useHttpRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const sendRequest = useCallback(async (requestConfig, applyData) => {
    setIsLoading(true);
    const urlResponse = await fetch('api/app-config');
    const urlResult = await urlResponse.json();
    const result = await fetch(`${urlResult.webApiUrl}${requestConfig.url}`, {
      method: requestConfig.method ? requestConfig.method : 'GET',
      headers: requestConfig.headers ? requestConfig.headers : {},
      body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
    });
    let data;
    if (result.ok) {
      if (isJsonResponse(result)) {
        data = await result.json();
      } else {
        data = await result.text();
      }
      setIsLoading(false);
      setData(data);
      if (applyData) applyData(data);
    }
  }, []);

  return {
    isLoading,
    sendRequest,
    data,
  };
};

export default useHttpRequest;
