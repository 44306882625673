import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Script from 'react-load-script';
import { CircularProgress } from '@material-ui/core';

const LoadScriptWrapper = ({ url, showLoading = true, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const getLoadingElement = () => (showLoading ? <CircularProgress /> : <span />);

  return (
    <>
      <Script
        url={url}
        onError={() => {
          throw new Error('Invalid script!!!');
        }}
        onLoad={() => setIsLoading(false)}
      />
      {isLoading ? getLoadingElement() : children}
    </>
  );
};

export default LoadScriptWrapper;

LoadScriptWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  showLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
};
