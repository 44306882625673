import React, { useEffect, useMemo, useState } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import PropTypes from 'prop-types';
import { hooks } from 'botframework-webchat-component';
import { Typography, makeStyles } from '@material-ui/core';
import useLastActivity from '../hooks/useLastActivity';
import { EventList } from '../utils/EventList';
import { MessageList } from '../utils/MessageList';
import ClaimButton from '../../../components/ClaimButton';
import { BoxLoading } from '../../../components/LoadingSuspense';
import LoadScriptWrapper from '../../../components/LoadScriptWrapper';
import GoogleMapsWithMarkers from './google-maps/GoogleMapsWithMarkers';

const { useSendEvent } = hooks;

const useStyles = makeStyles(() => ({
  mapModalOverlay: {
    padding: '24px',
  },
  headers: {
    marginBottom: '20px'
  },
  loadingHeaders: {
    paddingBottom: '64px'
  },
  loadingContainer: {
    height: '160px'
  },
  containerElement: {
    height: `400px`,
    marginBottom: '20px',
    width: '100%',
    border: '1px solid lightgray'
  },
  mapElement: { height: `100%` },
  noMarkersContainer: {
    marginTop: '50px',
    marginBottom: '50px',
  }
}));

const GoogleMapsWithMarkersButton = ({
  cardAction: { displayText, value },
  sendMessageBackWithPdfCheck,
  ...buttonProperties
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [callWasMade, setCallWasMade] = useState(false);
  const [markers, setMarkers] = useState([]);
  const modalLabels = JSON.parse(value);
  const classes = useStyles();
  const lastEvent = useLastActivity('event');
  const sendEvent = useSendEvent();

  const googleMapsUrl = useMemo(
    () => `${window.location.protocol}//${window.location.host}/api/app-config/maps-script`,
    [],
  );

  const handleMarkerSubmit = (marker, info) => {
    const size = '400x400';
    const mapsStaticApiUrl = `${window.location.protocol}//${window.location.host}/api/app-config/static-map/`;
    const coordinate = `${marker.latitude},${marker.longitude}`;
    const locationImage = `${mapsStaticApiUrl}?center=${coordinate}&markers=color:red|${coordinate}&zoom=13&size=${size}`;
    const message = info.join(', ');

    setTimeout(() => {
      sendEvent(EventList.ProvideMapSnapshot, {
        contentUrl: locationImage,
        name: 'googleResult',
        contentType: 'image/jpg',
      });
    }, 150);

    sendMessageBackWithPdfCheck(marker, message, message, MessageList.ContinueGoogleMapMarkersFlowMessage);
  };

  useEffect(() => {
    if (lastEvent?.name === EventList.ReturnGoogleMapMarkersEvent) {
      setMarkers(JSON.parse(lastEvent.value));
      setIsLoading(false);
      setCallWasMade(true);
    }
  }, [lastEvent]);

  const noMarkers = () => !(markers?.length > 0);

  const openModalAndRequestMarkers = () => {
    setIsOpen(true);
    if (noMarkers() && isLoading) sendEvent(EventList.GetGoogleMapMarkersEvent);
  };

  const repairersFound = !(!callWasMade || noMarkers());
  const buttonText = repairersFound ?  modalLabels.cancelButton : (modalLabels.continueButton || modalLabels.cancelButton);
  const messageName = repairersFound ? MessageList.ContinueJourneyOnNoRepairerSelected : MessageList.ContinueJourneyOnFailedFlow;

  return (
    <>
      <ClaimButton handleClick={openModalAndRequestMarkers} label={displayText} {...buttonProperties} />
      <div style={{ display: !isOpen ? 'none' : '' }}>
        <ModalOverlay className={classes.mapModalOverlay} open={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlayBody>
            {isLoading ? (
              <>
                <div className={classes.loadingHeaders}>
                  <ModalOverlayTitle>{modalLabels.loadingHeader}</ModalOverlayTitle>
                  {modalLabels.loadingSubHeader && <Typography>{modalLabels.loadingSubHeader}</Typography>}
                </div>
                <div className={classes.loadingContainer}>
                  <BoxLoading />
                </div>
              </>
            ) : noMarkers() ? (
              <Typography className={classes.noMarkersContainer}>{modalLabels.noMarkers}</Typography>
            ) : (
              <>
                <div className={classes.headers}>
                  <ModalOverlayTitle>{modalLabels.header}</ModalOverlayTitle>
                  {modalLabels.subHeader && <Typography>{modalLabels.subHeader}</Typography>}
                </div>
                <LoadScriptWrapper url={googleMapsUrl} showLoading={false}>
                  <GoogleMapsWithMarkers
                    markers={markers}
                    modalLabels={modalLabels}
                    containerElement={<div className={classes.containerElement} />}
                    mapElement={<div className={classes.mapElement} />}
                    handleMarkerSubmit={handleMarkerSubmit}
                  />
                </LoadScriptWrapper>
              </>
            )}
            {!isLoading && <ClaimButton handleClick={() => sendMessageBackWithPdfCheck(buttonText, buttonText, buttonText, messageName)} label={ buttonText } />}
            </ModalOverlayBody>
        </ModalOverlay>
      </div>
    </>
  );
};

export default GoogleMapsWithMarkersButton;

GoogleMapsWithMarkersButton.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  sendMessageBackWithPdfCheck: PropTypes.func,
};
