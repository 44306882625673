import PropTypes from 'prop-types';
import TenantPermissions from '../enums/TenantPermissions';
import { useQuery } from 'react-query';
import { useApiConfigs } from './ApiConfigsContextProvider';

function TenantPermissionProvider({ children, permission }) {
  const { webApiUrl } = useApiConfigs();
  const { data } = useQuery(`${webApiUrl}/api/client-config/permission/${permission}`, {
    suspense: true,
  });
  if (!data) {
    throw new Error('You do not have permission for this resource');
  }

  return children;
}

TenantPermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  permission: PropTypes.oneOf(Object.values(TenantPermissions)).isRequired,
};

export default TenantPermissionProvider;
