import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { colorPalette } from 'ri-components';

const { black } = colorPalette;

const useStyles = makeStyles({
  icon: {
    color: black,
  },
});

const Arrow = ({ onClick, className, style, direction }) => {
  const classes = useStyles();

  return (
    <div className={className} style={{ ...style, display: 'block' }} onClick={onClick}>
      {direction === 'left' && <ChevronLeftIcon className={classes.icon} />}
      {direction === 'right' && <ChevronRightIcon className={classes.icon} />}
    </div>
  );
};

export default Arrow;

Arrow.defaultProps = {
  direction: 'left',
};

Arrow.propTypes = {
  direction: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};
