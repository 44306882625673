import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useScrollToBottom } from '../../components/ScrollToBottom';

const ConversationWrapper = ({ children, shouldScrollToBottom }) => {
  const scrollToBottom = useScrollToBottom();

  useEffect(() => {
    if (shouldScrollToBottom === true) scrollToBottom();
  }, [shouldScrollToBottom, scrollToBottom]);

  return <>{children}</>;
};

ConversationWrapper.propTypes = {
  children: PropTypes.any,
  shouldScrollToBottom: PropTypes.bool,
};
export default ConversationWrapper;
