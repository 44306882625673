export const AllMonths = Object.freeze([
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]);

export const AllDays = getDaysArray(31);
export const DefaultMinYearDiff = 100;
export const DefaultMaxYearDiff = 0;

export function getDaysInMonths(year) {
  return {
    January: 31,
    February: isLeapYear(year) ? 29 : 28,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
  };
}

export function isLeapYear(year) {
  if (!year) return true;
  // Leap years are divisible by 4.
  // However, years divisible by 100 are not leap years, unless they are divisible by 400.
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function getDaysArray(length) {
  return Array.from({ length }).map((_, idx) => idx + 1);
}
