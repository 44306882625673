import { createTheme } from '@material-ui/core';
import { colorPalette } from 'ri-components';
import { globalTypography } from './GlobalThemeProvider';

const { coolGrey, white, black, primary } = colorPalette;

export const defaultWebChatTheme = createTheme({
  // Default value for spacing in Material UI is 8px
  spacing: 8,

  palette: {
    background: {
      default: '#f1f3f6',
    },
    primary: {
      main: primary.main,
      dark: primary.dark,
      light: primary.light,
    },
    secondary: {
      main: coolGrey[3],
    },
    success: {
      main: '#00d674',
    },
  },

  typography: {
    ...globalTypography,
    // bot
    body1: {
      fontSize: 17,
      color: black,
      letterSpacing: '0.1px',
      lineHeight: '21px',
      overflowWrap: 'break-word',
    },

    // user
    body2: {
      fontSize: 17,
      color: white,
      letterSpacing: '0.1px',
      lineHeight: '21px',
      overflowWrap: 'break-word',
    },

    button: {
      fontSize: 17,
      letterSpacing: '-0.1px',
      lineHeight: '24px',
    },
  },
  message: {
    bot: {
      color: black,
      backgroundColor: coolGrey[3],
      typingPointsBackgroundColor: '',
    },
    user: {
      color: white,
      backgroundColor: primary.main,
    },
  },
});
