import React, { useEffect } from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EventEmitter from './utils/EventEmitter';
import { selectedFiles } from './suggested-actions/UploadFileButton';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import useFocusOnTab from './hooks/useFocusOnTab';
import { colorPalette } from 'ri-components';

const { black, coolGrey } = colorPalette;

const useStyles = makeStyles({
  wrapper: {
    width: '80px',
    height: '80px',
    border: '1px solid gray',
    borderRadius: '5px',
    padding: '20px',
    backgroundColor: coolGrey[3],
    margin: '5px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:focus': {
      outline: 'none',
    },
  },
  refreshIcon: {
    cursor: 'pointer',
    color: black,
    padding: '8px',
    '&:focus': {
      outline: 'none',
    },
  },
  errorIcon: {
    color: black,
    verticalAlign: '-webkit-baseline-middle',
  },
});

const UploadFailed = ({ isLastFailUpload }) => {
  const classes = useStyles();

  const focusOnRetryUploadWrapper = useFocusOnTab('retry-upload-wrapper', 'button');

  useEffect(() => {
    if (isLastFailUpload && selectedFiles.length > 0) {
      setTimeout(() => {
        focusOnRetryUploadWrapper();
      }, 200);
    }
  }, [isLastFailUpload, focusOnRetryUploadWrapper]);

  return (
    <div className={classes.wrapper} id='retry-upload-wrapper'>
      {isLastFailUpload && selectedFiles.length > 0 && (
        <IconButton
          tabIndex='0'
          className={classes.refreshIcon}
          onClick={() => {
            EventEmitter.emit('RetryUploading');
          }}
        >
          <RefreshIcon />
        </IconButton>
      )}
      {!(isLastFailUpload && selectedFiles.length > 0) && <ErrorOutlineIcon className={classes.errorIcon} />}
    </div>
  );
};

UploadFailed.propTypes = {
  isLastFailUpload: PropTypes.bool,
};

export default UploadFailed;
