import { Box } from '@material-ui/core';
import React from 'react';
import { Typo } from 'ri-components';

function Unauthorized() {
  return (
    <Box textAlign='center'>
      <Typo variant='title2'>401 - You are unauthorized</Typo>
    </Box>
  );
}

export default Unauthorized;
