import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { hooks } from 'botframework-webchat-component';
import { makeStyles } from '@material-ui/core/styles';
import ClaimButton from '../../../components/ClaimButton';

const { useSendMessage } = hooks;

const useStyles = makeStyles((theme) => ({
  listWrapper: {
    listStyleType: 'none',
    paddingInlineStart: '0px',
    textAlign: 'center',
  },
  buttonWrapper: {
    margin: theme.spacing(0.5, 0),
  },
}));

const ChoiceSetAdaptiveCard = ({ adaptiveCard }) => {
  const sendMessage = useSendMessage();
  const classes = useStyles();

  const activities = adaptiveCard.body.filter(({ type }) => type !== 'TextBlock');
  const actions = adaptiveCard.actions;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const addOption = (option) => {
    setSelectedOptions([...selectedOptions, option]);
  };

  const optionClicked = (option) => {
    if (selectedOptions.some((x) => x === option)) {
      setSelectedOptions(selectedOptions.filter((x) => x !== option));
      return;
    }
    addOption(option);
  };

  const submitValues = useCallback(() => sendMessage(selectedOptions.join(', ')), [
    selectedOptions,
    sendMessage,
  ]);

  return (
    <div>
      {activities
        .filter(({ type }) => type === 'Input.ChoiceSet')
        .map(({ id, choices }) => (
          <React.Fragment key={id}>
            <div className={classes.listWrapper}>
              {choices.map(({ title, value }, index) => (
                <div key={index} className={classes.buttonWrapper}>
                  <ClaimButton enableToggle label={title} handleClick={() => optionClicked(value)} />
                </div>
              ))}
            </div>
          </React.Fragment>
        ))}
      {actions
        .filter(({ type }) => type === 'Action.Submit')
        .map(({ title }) => (
          <div key={title} style={{ textAlign: 'center' }}>
            <ClaimButton label={title} variant='text' handleClick={submitValues} />
          </div>
        ))}
    </div>
  );
};

export default ChoiceSetAdaptiveCard;

ChoiceSetAdaptiveCard.defaultProps = {
  adaptiveCard: undefined,
};

ChoiceSetAdaptiveCard.propTypes = {
  adaptiveCard: PropTypes.shape({
    type: PropTypes.string,
    body: PropTypes.array,
    actions: PropTypes.array,
  }),
};
