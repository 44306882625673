import axios from "axios";

const fetchWithTimeout = async (primaryUrl, fallbackUrl, timeout = 1000) => {
  const fetchWithTimeoutPromise = (url, timeout) => {
    return Promise.race([
      axios.get(url)
        .then(res => {
          if (res.data.ipAddress) {
            return res.data.ipAddress;
          }else if(res.data.ip){
            return res.data.ip;
          }
        }),
      new Promise((_, reject) =>
        setTimeout(() => reject(), timeout)
      )
    ]);
  };

  const primaryFetch = fetchWithTimeoutPromise(primaryUrl, timeout);

  const fallbackFetch = () => fetchWithTimeoutPromise(fallbackUrl, timeout);

  try {
    return await Promise.race([
      primaryFetch,
      new Promise((__, reject) => setTimeout(() => reject(), timeout)
      )
    ]);
  } catch {
    return await fallbackFetch();
  }
};

const userLocationUtils = {
  getUserIPAddress() {
    return fetchWithTimeout('https://freeipapi.com/api/json/', 'https://ipapi.co/json/')
      .catch(() => 'Unknown');
  }
};

export default userLocationUtils;
