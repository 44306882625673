import React, { useState } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import PropTypes from 'prop-types';
import ClaimButton from '../../../../components/ClaimButton';
import { makeStyles } from '@material-ui/core/styles';
import AutocompleteItemSearch from './AutocompleteItemSearch';

const useStyles = makeStyles(() => ({
  mapModalOverlay: {
    padding: '24px',
  },
}));

const BeValuedItemLookup = ({
  cardAction: { displayText, value },
  sendMessageBackWithPdfCheck,
  ...buttonProperties
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAutocompleteSearchOpen, setIsAutocompleteSearchOpen] = useState(false);
  const valueJson = JSON.parse(value);
  const apiToken = valueJson.ApiToken;
  const modalLabels = JSON.parse(valueJson.ModalLabels);
  const webApiEndpoint = valueJson.WebApiEndpoint;
  const classes = useStyles();

  return (
    <>
      <ClaimButton handleClick={() => setIsOpen(true)} label={displayText} {...buttonProperties} />
      <div style={{ display: !isOpen ? 'none' : '' }}>
        <ModalOverlay className={classes.mapModalOverlay} open={isOpen} onClose={() => setIsOpen(false)}>
          <ModalOverlayTitle style={{ marginBottom: '-28px' }}>
            {isAutocompleteSearchOpen ? modalLabels.itemDetailsHeader : modalLabels.header}
          </ModalOverlayTitle>
          <ModalOverlayBody>
            <AutocompleteItemSearch
              modalLabels={modalLabels}
              webApiToken={apiToken}
              setIsAutocomplete={setIsAutocompleteSearchOpen}
              searchTimeout={400}
              sendMessageBackWithPdfCheck={sendMessageBackWithPdfCheck}
              webApiEndpoint={webApiEndpoint}
            />
          </ModalOverlayBody>
        </ModalOverlay>
      </div>
    </>
  );
};

export default BeValuedItemLookup;

BeValuedItemLookup.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  sendMessageBackWithPdfCheck: PropTypes.func,
  webApiEndpoint: PropTypes.string,
};
