import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ClaimButton from '../../../components/ClaimButton';
import { resetSelectedFiles } from './UploadFileButton';
import { DateFormats, isDate } from '../../../utils/dateUtils';
import moment from 'moment';

const MessageBackButton = ({
  cardAction: { displayText, text, title, value },
  buttonProperties,
  sendMessageBackWithPdfCheck,
}) => {
  const handleClick = useCallback(() => {
    let submittedText = isDate(value) ? moment(value).local().format(DateFormats.displayFormat) : text;
    sendMessageBackWithPdfCheck(value, submittedText, displayText);
  }, [value, text, displayText, sendMessageBackWithPdfCheck]);

  // Web Chat does the heavylifting for us by exposing a "sendMessageBack" function.
  // We do need to handle "displayText" manually in our renderer though.
  return (
    <ClaimButton
      handleClick={() => {
        resetSelectedFiles();
        handleClick();
      }}
      label={title}
      {...buttonProperties}
    />
  );
};

export default MessageBackButton;

MessageBackButton.defaultProps = {
  cardAction: undefined,
  buttonProperties: undefined,
};

MessageBackButton.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  sendMessageBackWithPdfCheck: PropTypes.func,
};
