import React, { createContext, useContext, useState, useEffect } from 'react';
import { BoxLoading } from '../components/LoadingSuspense';
import PropTypes from 'prop-types';

function useTenantOauthConfigs() {
  const [isLoading, setIsLoading] = useState(true);
  const [configs, setConfigs] = useState(null);

  useEffect(() => {
    (async () => {
      await fetch('api/app-config')
        .then(async (response) => {
          const { webApiUrl } = await response.json();
          var tenantAuthenticationDetails = {};

          await fetch(`${webApiUrl}/api/authentication-configuration`, {
            method: 'GET',
          })
            .then(async (result) => {
              var res = await result.json();
              tenantAuthenticationDetails = res;
            })
            .catch(() => {});

          const publicKeyResponse = await fetch(`${webApiUrl}/api/Encryption/public-key`, {
            method: 'GET',
          });

          const publicKey = await publicKeyResponse.text();
          tenantAuthenticationDetails.publicKey = publicKey;
          setConfigs(tenantAuthenticationDetails);
          setIsLoading(false);
        })
        .catch(() => {
          setConfigs({});
          setIsLoading(false);
        });
    })();
  }, []);

  return { isLoading, configs };
}

export const TenantOauthConfigContext = createContext(null);
export const useOauthApiConfigs = () => useContext(TenantOauthConfigContext);

export const withOauthConfigs = (Component) => {
  return (props) => {
    const configs = useOauthApiConfigs();
    return <Component oauthConfigs={configs} {...props} />;
  };
};

function TenantOauthContextProvider({ children }) {
  const { isLoading, configs } = useTenantOauthConfigs();

  return (
    <TenantOauthConfigContext.Provider value={configs}>
      {isLoading ? <BoxLoading /> : children}
    </TenantOauthConfigContext.Provider>
  );
}

TenantOauthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TenantOauthContextProvider;
