function base64URLEncode(str) {
    return btoa(String.fromCharCode.apply(null, new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  }
  
  async function sha256(plain) {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return crypto.subtle.digest('SHA-256', data);
  }
  
  export async function generatePKCECodes() {
    const codeVerifier = base64URLEncode(crypto.getRandomValues(new Uint8Array(32)));
    const codeChallengeBuffer = await sha256(codeVerifier);
    const codeChallenge = base64URLEncode(codeChallengeBuffer);
    return { codeVerifier, codeChallenge };
  }