import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ClaimButton from '../../../components/ClaimButton';
import { hooks } from 'botframework-webchat-component';
import { uploadFiles } from '../../../services/FileService';
import EventEmitter from '../utils/EventEmitter';
import { EventList } from '../utils/EventList';
import { useClaimFile, useClaimFileLastStepKey } from '../../../services/ClaimFileContextProvider';
import { withApiConfigs } from '../../../services/ApiConfigsContextProvider';
import useLastActivity from '../hooks/useLastActivity';
import CameraWithOverlay from './CameraWithOverlay';

const { useSendMessageBack, useSendEvent, useSendPostBack } = hooks;

let selectedFiles = [];
const resetSelectedFiles = () => {
  selectedFiles = [];
};

export { selectedFiles, resetSelectedFiles };

const UploadFileButton = ({
  webApiUrl,
  cardAction: { title, text, value },
  buttonProperties,
  disableAllActionsCallBack,
}) => {
  const lastActivity = useLastActivity();
  const sendMessageBack = useSendMessageBack();
  const sendEvent = useSendEvent();
  const sendPostBack = useSendPostBack();
  const uploadRef = useRef(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const inputSettings = value ? JSON.parse(value) : null;
  const isSingleFileUpload = inputSettings?.AcceptMultipleFiles && !inputSettings.AcceptMultipleFiles;
  const webApiToken = inputSettings?.ApiToken ?? '';
  const stepKey = inputSettings?.StepKey ?? '';
  const isReplaceable = inputSettings?.IsReplaceable ?? false;
  const hideDeleteFileIcon = inputSettings?.HideDeleteFileIcon ?? false;
  const isCameraUpload = inputSettings?.IsCameraUpload ?? false;
  const cameraOverlayName = inputSettings?.CameraOverlayName;
  const modalTitle = inputSettings?.Title;

  const claimStepFiles = useClaimFile();
  const lastStepKey = useClaimFileLastStepKey();

  const acceptedTypes = inputSettings && inputSettings.AcceptedTypes ? inputSettings.AcceptedTypes : '';

  useEffect(() => {
    return () => {
      if (!claimStepFiles.current.find((claimStep) => claimStep.stepKey === stepKey)) {
        lastStepKey.current = stepKey;
        claimStepFiles.current = [
          ...claimStepFiles.current,
          {
            stepKey,
            files: [],
          },
        ];
      }
    };
  }, [claimStepFiles, stepKey, lastStepKey]);

  const setKeyStepFilesValue = useCallback(
    (newFiles) => {
      lastStepKey.current = stepKey;

      const currentStepItem = claimStepFiles.current.find((claimStep) => claimStep.stepKey === stepKey);
      if (!currentStepItem) {
        claimStepFiles.current = [
          ...claimStepFiles.current,
          {
            stepKey,
            files: newFiles,
          },
        ];

        return;
      }

      if (isReplaceable) {
        const filesToDelete = currentStepItem.files.map((file) => ({
          name: file.name,
          fileId: file.id,
        }));

        sendEvent(EventList.RemoveFileEvent, JSON.stringify(filesToDelete));
      }

      const newCurrentStepItem = {
        stepKey: currentStepItem.stepKey,
        files: isReplaceable ? newFiles : currentStepItem.files.concat(newFiles),
      };

      const newClaimStepFiles = claimStepFiles.current.map((claimStep) =>
        claimStep.stepKey === stepKey ? newCurrentStepItem : claimStep,
      );
      claimStepFiles.current = newClaimStepFiles;
    },
    [claimStepFiles, stepKey, lastStepKey, isReplaceable, sendEvent],
  );

  const saveFile = useCallback(
    (files) => {
      if (files.length === 0) {
        return;
      }

      let uploadFailed = false;
      selectedFiles = Array.from(files);
      disableAllActionsCallBack(true);

      const shouldShowMessageBackInWebChat =
        inputSettings && inputSettings.ShowSelectedActionInChat !== undefined
          ? inputSettings.ShowSelectedActionInChat
          : true;

      if (shouldShowMessageBackInWebChat) {
        sendMessageBack(null, text, title);
      }

      const resetAfterGettingResponse = (eventName, eventValue) => {
        sendEvent(eventName, eventValue);
        disableAllActionsCallBack(false);
      };

      uploadFiles(
        webApiUrl,
        webApiToken,
        selectedFiles,
        acceptedTypes,
        lastActivity?.conversation?.id,
        inputSettings.Sync ?? false,
      )
        .then((res) => {
          if (res.data.savedFiles && res.data.savedFiles.length > 0) {
            setKeyStepFilesValue(res.data.savedFiles);
            const currentStepItem = claimStepFiles.current.find((claimStep) => claimStep.stepKey === stepKey);
            resetSelectedFiles();

            const stepItemFiles = currentStepItem.files.map((file) =>
              Object.assign({}, file, { stepKey }),
            );

            resetAfterGettingResponse(EventList.SaveFilesEvent, JSON.stringify(stepItemFiles));
            
            if (hideDeleteFileIcon) {
              const value = res.data.savedFiles.map((file) =>
                Object.assign({}, file, { hideDeleteButton: true, stepKey }),
              );
              sendEvent(EventList.RenderFilesEvent, JSON.stringify(value));
            } else {
              const value = res.data.savedFiles.map((file) =>
                Object.assign({}, file, { stepKey }),
              );
              sendEvent(EventList.RenderFilesEvent, JSON.stringify(value));
            }
          }
          if (res.data.unsavedFiles && res.data.unsavedFiles.length > 0) {
            uploadFailed = true;
            setTimeout(() => {
              resetAfterGettingResponse(EventList.UploadFailedEvent, JSON.stringify(res.data.unsavedFiles));
              if (inputSettings?.HideSuggestedActions || shouldShowMessageBackInWebChat) {
                sendPostBack(null);
              }
            }, 500);
          }
        })
        .then(() => {
          if ((inputSettings?.HideSuggestedActions || shouldShowMessageBackInWebChat) && !uploadFailed) {
            //Hide the buttons if there are activities to show after upload
            sendPostBack(null);
            //Show the buttons after the activities are shown
            sendEvent(EventList.ShowSuggestedActionsEvent, null);
          }
        })
        .catch((error) => {
          resetAfterGettingResponse(EventList.UploadFailedEvent, error);
        });
    },
    [
      disableAllActionsCallBack,
      inputSettings,
      webApiUrl,
      webApiToken,
      sendMessageBack,
      text,
      title,
      sendEvent,
      setKeyStepFilesValue,
      claimStepFiles,
      hideDeleteFileIcon,
      stepKey,
      sendPostBack,
      lastActivity,
      acceptedTypes,
    ],
  );

  useEffect(() => {
    const onRetryUploading = () => {
      saveFile(selectedFiles);
    };

    const retryUploadingEventListener = EventEmitter.addListener(EventList.RetryUploading, onRetryUploading);

    return () => {
      retryUploadingEventListener.remove();
    };
  }, [saveFile]);

  const handleUploadClick = () => {
    uploadRef.current.click();
  };

  const handleTakePhotoClick = () => {
    setIsCameraOpen(true);
  };

  if (isCameraUpload) {
    return (
      <>
        <ClaimButton handleClick={handleTakePhotoClick} label={title} {...buttonProperties} />
        <CameraWithOverlay
          isOpen={isCameraOpen}
          setIsOpen={setIsCameraOpen}
          onChange={(event) => saveFile(event.files)}
          overlayName={cameraOverlayName}
          title={modalTitle}
        />
      </>
    );
  }

  return (
  <>
    <ClaimButton handleClick={handleUploadClick} label={title} {...buttonProperties} />
    <input
      type='file'
      accept={acceptedTypes}
      ref={uploadRef}
      style={{ display: 'none' }}
      onChange={(event) => saveFile(event.target.files)}
      multiple={!isSingleFileUpload}
    />
  </>
);
};

export default withApiConfigs(UploadFileButton);

UploadFileButton.defaultProps = {
  cardAction: undefined,
  buttonProperties: undefined,
};

UploadFileButton.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  webApiUrl: PropTypes.string,
  disableAllActionsCallBack: PropTypes.func,
};
