import SendBoxInputContext from './SendBoxInputContext';
import React, { useState } from 'react';
import { PropTypes } from 'prop-types';

const SendBoxInputProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState('');

  const setValue = (value) => {
    setInputValue(value);
  };

  const inputContext = {
    setValue: setValue,
    inputValue: inputValue,
  };

  return <SendBoxInputContext.Provider value={inputContext}>{children}</SendBoxInputContext.Provider>;
};
SendBoxInputProvider.propTypes = {
  children: PropTypes.any,
};

export default SendBoxInputProvider;
