import React, { useState, useCallback, useEffect } from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import ClaimButton from '../../../components/ClaimButton';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import useAccessibility from '../hooks/useAccessibility';
import { DateFormats } from '../../../utils/dateUtils';
import { localeMap } from '../utils/LocaleMap';

const customTheme = createTheme({
  overrides: {
    MuiPickersModal: {
      dialogRoot: {
        '@media (prefers-contrast: more)': {
          border: '3px solid rgba(0,0,0,0.0)',
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#575757',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: 'black',
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: 'white',
      },
    },
    MuiButton: {
      textPrimary: {
        color: 'black',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: 'black',
        '&:hover': {
          backgroundColor: 'black',
        },
        '&:focus': {
          backgroundColor: 'black',
        },
      },
      day: {
        color: 'black',
      },
      current: {
        color: 'black',
      },
      dayDisabled: {
        color: 'black',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: 'black',
      },
    },
  },
});

const DatePickerButton = ({
  cardAction: { title, value },
  buttonProperties,
  sendMessageBackWithPdfCheck,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  let minDate = new Date('1900-01-01');
  if (Object.prototype.hasOwnProperty.call(value, 'mindate')) {
    minDate = value['mindate'];
  }
  const [locale] = useState(!value.language || value.language === 'en' ? localeMap.en : value.language);
  moment.locale(locale);

  const startDate = new Date();
  startDate.setHours(0, 0, 0, 0);

  const handleClick = useCallback(
    (date) => {
      let momentDate = moment(date);
      let utcDate = momentDate.format(DateFormats.utcDateFormat);
      let formattedDate = momentDate.format(DateFormats.displayFormat);
      sendMessageBackWithPdfCheck(utcDate, formattedDate, formattedDate, null, value);
    },
    [sendMessageBackWithPdfCheck, value],
  );

  const { scrollToPrevMonth, scrollToNextMonth } = useAccessibility();

  useEffect(() => {
    // accessibility
    setTimeout(() => {
      const monthButtons = document.querySelectorAll(`[class*="MuiPickersCalendarHeader-iconButton"]`);

      if (monthButtons?.length >= 2) {
        monthButtons[0].setAttribute('aria-label', scrollToPrevMonth);
        monthButtons[1].setAttribute('aria-label', scrollToNextMonth);
      }
    }, 0);
  });

  return (
    <React.Fragment>
      <ClaimButton handleClick={() => setIsOpen(true)} label={title} {...buttonProperties} />
      <div style={{ display: 'none' }} data-testid='date-picker-wrapper'>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
          <ThemeProvider theme={customTheme}>
            <DatePicker
              open={isOpen}
              onClose={() => setIsOpen(false)}
              onChange={handleClick}
              disableFuture
              value={startDate}
              minDate={minDate}
              format={DateFormats.displayFormat}
              cancelLabel={value.cancellabel}
              okLabel={value.oklabel}
            />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </div>
    </React.Fragment>
  );
};

export default DatePickerButton;

DatePickerButton.defaultProps = {
  cardAction: {
    title: '',
    value: {
      value: '',
      cancelLabel: 'Cancel',
      okLabel: 'Ok',
      language: localeMap.en,
      mindate: '',
    },
  },
  buttonProperties: undefined,
};

DatePickerButton.propTypes = {
  cardAction: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.shape({
      value: PropTypes.string,
      cancellabel: PropTypes.string,
      oklabel: PropTypes.string,
      language: PropTypes.any,
      mindate: PropTypes.string,
    }),
  }),
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
  sendMessageBackWithPdfCheck: PropTypes.func,
};
