import { hooks } from 'botframework-webchat-component';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ClaimButton from '../../../components/ClaimButton';

const { useSendPostBack } = hooks;

const PostBackButton = ({ cardAction: { title, value }, buttonProperties }) => {
  const sendPostBack = useSendPostBack();
  const handleClick = useCallback(() => sendPostBack(value), [value, sendPostBack]);

  // Web Chat does the heavylifting for us by exposing a "sendPostBack" function.
  return <ClaimButton handleClick={handleClick} label={title} {...buttonProperties} />;
};

export default PostBackButton;

PostBackButton.defaultProps = {
  cardAction: undefined,
  buttonProperties: undefined,
};

PostBackButton.propTypes = {
  cardAction: PropTypes.object,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};
