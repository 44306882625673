import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { colorPalette } from 'ri-components';

const { grey, coolGrey, black, white } = colorPalette;

const StyledButton = withStyles((theme) => ({
  root: ({ fullWidth }) => ({
    borderRadius: '20px',
    color: 'black !important',
    padding: theme.spacing(1.5, 2),
    maxWidth: fullWidth ? 'none' : '400px',
    fontWeight: 400,
    lineHeight: '22px',
    transition: '200ms',
    '&:hover': {
      outline: '4px solid #ACAEB2',
      borderColor: '#ACAEB2',
    },
    '&:focus': {
      outline: '4px solid ' + theme.palette.primary.main,
    },
    [theme.breakpoints.between('xs', 350)]: {
      maxWidth: fullWidth ? 'none' : '240px',
    },
    [theme.breakpoints.between(350, 420)]: {
      maxWidth: fullWidth ? 'none' : '290px',
    },
  }),
  label: {
    textTransform: 'none',
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
  },
  text: {
    '&:hover': {
      backgroundColor: coolGrey[3],
    },
    '&:focus': {
      backgroundColor: coolGrey[3],
    },
  },
  textPrimary: {
    color: theme.palette.primary.main,
  },
  textSecondary: {
    color: black,
  },
  outlined: {
    border: 'solid 1px',
    width: '100%',
  },
  outlinedPrimary: {
    color: black,
    borderColor: grey[5],
    backgroundColor: white,
    borderRadius: '20px',
    '&:hover': {
      borderColor: grey[6],
      backgroundColor: white,
    },
    '&:focus': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
    },
  },
  outlinedSecondary: {
    color: theme.palette.primary.main,
    borderColor: coolGrey[3],
    backgroundColor: coolGrey[3],
    borderRadius: '14px',
    '&:hover': {
      borderColor: coolGrey[5],
      backgroundColor: coolGrey[5],
    },
    '&:focus': {
      borderColor: coolGrey[5],
      backgroundColor: coolGrey[5],
    },
  },
  sizeSmall: {
    width: 'auto',
    minHeight: '36px',
    padding: theme.spacing(0.75, 1.5),
    fontSize: 17,
  },
  disabled: {
    color: '#6d7278',
    backgroundColor: '#e0e4e6 !important',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  default: {
    color: black,
    borderColor: grey[5],
  },
  toggled: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  icon: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const ClaimButton = ({
  handleClick,
  enableToggle,
  label,
  disabled,
  color,
  size,
  variant,
  fullWidth,
  hasIcon,
  children,
  externalClassName,
}) => {
  const classes = useStyles({ fullWidth });
  const buttonRef = useRef(null);
  const [toggled, setToggled] = useState(false);
  const [iconVisible, setIconVisible] = useState(false);

  const onButtonClick = () => {
    if (enableToggle) {
      buttonRef.current.blur();
      setToggled(!toggled);
      setIconVisible(!iconVisible);
    }

    handleClick();
  };
  return (
    <>
      <StyledButton
        fullWidth={fullWidth}
        ref={buttonRef}
        className={toggled ? classes.toggled : enableToggle ? classes.default : '' + externalClassName}
        variant={variant}
        color={color}
        size={size}
        disabled={disabled}
        disableRipple
        onClick={onButtonClick}
        tabIndex='0'
        data-testid='claim-button'
        id='button-option'
      >
        {label}
        <span className={classes.icon} style={{ display: hasIcon && iconVisible ? 'flex' : 'none' }}>
          {children}
        </span>
      </StyledButton>
    </>
  );
};

export default ClaimButton;

ClaimButton.defaultProps = {
  label: '',
  enableToggle: false,
  disabled: false,
  color: 'primary',
  size: 'medium',
  variant: 'outlined',
  fullWidth: false,
};

ClaimButton.propTypes = {
  handleClick: PropTypes.func,
  label: PropTypes.string,
  enableToggle: PropTypes.bool,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  variant: PropTypes.oneOf(['text', 'outlined']),
  fullWidth: PropTypes.bool,
  hasIcon: PropTypes.bool,
  children: PropTypes.any,
  externalClassName: PropTypes.any,
};
