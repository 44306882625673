import { useMemo, useState, useEffect } from 'react';
import { hooks } from 'botframework-webchat-component';
import { getLastActivityByName } from '../utils/activity-utils';

const { useActivities } = hooks;

function useAccessibility() {
  const [activities] = useActivities();
  const [editAnswer, setEditAnswer] = useState(null);
  const [newAnswerInput, setNewAnswerInput] = useState(null);
  const [addressLineInput, setAddressLineInput] = useState(null);
  const [townOrCityInput, setTownOrCityInput] = useState(null);
  const [postCodeInput, setPostCodeInput] = useState(null);
  const [addressInput, setAddressInput] = useState(null);
  const [scrollToPrevMonth, setScrollToPrevMonth] = useState(null);
  const [scrollToNextMonth, setScrollToNextMonth] = useState(null);
  const [selectOptionAbove, setSelectOptionAbove] = useState(null);
  const [locationImage, setLocationImage] = useState(null);

  const initActivity = useMemo(() => getLastActivityByName(activities, 'SetAccessibilityLabelsDialog'), [
    activities,
  ]);

  useEffect(() => {
    if (initActivity === null) {
      return;
    }

    if (initActivity.editAnswer) {
      setEditAnswer(initActivity.editAnswer);
    }

    if (initActivity.newAnswerInput) {
      setNewAnswerInput(initActivity.newAnswerInput);
    }

    if (initActivity.addressLineInput) {
      setAddressLineInput(initActivity.addressLineInput);
    }
    
    if (initActivity.townOrCityInput) {
      setTownOrCityInput(initActivity.townOrCityInput);
    }
    
    if (initActivity.postCodeInput) {
      setPostCodeInput(initActivity.postCodeInput);
    }
    
    if (initActivity.addressInput) {
      setAddressInput(initActivity.addressInput);
    }
    
    if (initActivity.scrollToPrevMonth) {
      setScrollToPrevMonth(initActivity.scrollToPrevMonth);
    }
    
    if (initActivity.scrollToNextMonth) {
      setScrollToNextMonth(initActivity.scrollToNextMonth);
    }
    
    if (initActivity.selectOptionAbove) {
      setSelectOptionAbove(initActivity.selectOptionAbove);
    }

    if (initActivity.locationImage) {
      setLocationImage(initActivity.locationImage);
    }
  }, [initActivity]);

  return {
    editAnswer: editAnswer,
    newAnswerInput: newAnswerInput,
    addressLineInput: addressLineInput,
    townOrCityInput: townOrCityInput,
    postCodeInput: postCodeInput,
    addressInput: addressInput,
    scrollToPrevMonth: scrollToPrevMonth,
    scrollToNextMonth: scrollToNextMonth,
    selectOptionAbove: selectOptionAbove,
    locationImage: locationImage,
  };
}

export default useAccessibility;
