import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';
import DeleteAttachmentButton from '../delete-attachment-button/DeleteAttachmentButton';
import { CircularProgress } from '@material-ui/core';
import { useState } from 'react';
import { isJsonResponse } from '../../../utils/isJsonResponse';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    margin: theme.spacing(0, 0.5, 1, 0.5),
  },
  image: {
    width: '80px',
    height: '80px',
    objectFit: 'cover',
    borderRadius: '2px',
    filter: (props) => (props.isActivityMasked ? 'blur(0.8rem)' : 'none'),
    background: (props) => (props.isActivityMasked ? 'grey' : 'none')
  },
  circularProgress: {
    color: theme.message.user.backgroundColor,
    marginTop: '20px',
    marginLeft: '20px',
  },
}));

async function fetchData(fileId, fileExtension, webApiToken) {
  const urlResponse = await fetch('api/app-config');
  const urlResult = await urlResponse.json();
  const result = await fetch(`${urlResult.webApiUrl}/api/files/get-file/${fileId}/${fileExtension}`, {
    method: 'GET',
    headers: {
      Authorization: 'Bearer ' + webApiToken,
    },
  });
  let data;
  if (result.ok) {
    if (isJsonResponse(result)) {
      data = await result.json();
    } else {
      data = await result.text();
    }
    return data;
  }
}

const PhotoChatDisplay = ({ webApiToken, name, fileId, fileExtension, activityId, isActivityMasked}) => {
  const classes = useStyles({ isActivityMasked });
  const [streamData, setStreamData] = useState(null);

  useEffect(() => {
    let mounted = true;
    if (!isActivityMasked) {
      fetchData(fileId, fileExtension, webApiToken).then((data) => {
        if (mounted) {
          setStreamData(data);
        }
      });
    }
    return () => (mounted = false);
  }, [fileId, fileExtension, webApiToken, isActivityMasked]);
  
  if(isActivityMasked){
    return <div className={classes.image}></div>
  }else{
  const displayElement = streamData ? (
    <div className={classes.root}>
      <CardMedia component='img' alt='Card' className={classes.image} src={streamData} />
      <DeleteAttachmentButton name={name} activityId={activityId} fileId={fileId} />
    </div>
  ) : (
    <div className={classes.image}>
      <CircularProgress className={classes.circularProgress} />{' '}
    </div>
  );
  return <>{displayElement}</>;
 }
};

export default PhotoChatDisplay;

PhotoChatDisplay.propTypes = {
  fileId: PropTypes.string,
  name: PropTypes.string,
  activityId: PropTypes.string,
  fileExtension: PropTypes.string,
  webApiToken: PropTypes.string,
  isActivityMasked: PropTypes.bool,
};
