import React, { createContext, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

export const ClaimFileContext = createContext(null);
export const useClaimFile = () => useContext(ClaimFileContext);
export const ClaimFileLastStepKeyContext = createContext(null);
export const useClaimFileLastStepKey = () => useContext(ClaimFileLastStepKeyContext);

export default function ClaimFileContextProvider({ children }) {
  const claimStepFiles = useRef([]);
  const lastStepKey = useRef([]);

  return  (
  <ClaimFileContext.Provider value={claimStepFiles}>
    <ClaimFileLastStepKeyContext.Provider value={lastStepKey}>   
      {children}
    </ClaimFileLastStepKeyContext.Provider>
  </ClaimFileContext.Provider>
  );
}

ClaimFileContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
